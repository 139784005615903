<template>
	<v-menu
			ref="menu"
			v-model="menu"
			:close-on-content-click="false"
			:return-value.sync="singleTime"
			transition="scale-transition"
			offset-y
			:max-width="menuWidth"
			:nudge-top="nudgeTop ? nudgeTop: (hideDetails ? '' : '20px')"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
					ref="textRef"
					:hide-details="hideDetails"
					:dense="dense"
					:class="clazz"
					v-model="singleTime"
					:label="label"
					:clearable="clearable"
					readonly
					v-bind="attrs"
					v-on="on"
			>
				<template v-slot:append>
					<v-icon>
						mdi-calendar-month-outline
					</v-icon>
				</template>
			</v-text-field>
		</template>
		<v-date-picker
				ampm-in-title
				v-if="menu"
				v-model="singleTime"
				full-width
				:day-format="dayFormat"
				color="primary"
				locale="zh-cn"
				@change="$refs.menu.save(singleTime)"
		></v-date-picker>
	</v-menu>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: () => ""
		},
		dense: {
			type: Boolean,
			default: () => false
		},
		hideDetails: {
			type: Boolean,
			default: false
		},
		clazz: {
			type: String,
			default: ''
		},
		value: {
			type: String,
			default: ''
		},
		nudgeTop: {
			type: String,
			default: ''
		},
		clearable: {
			type: Boolean,
			default: false
		}
	},
	model: {
		event: 'change'
	},
	watch: {
		singleTime(value) {
			this.$emit("change", value);
		}
	},
	data() {
		return {
			menu: false,
			singleTime: '',
			menuWidth: ''
		}
	},
	methods: {
		dayFormat(e) {
			// 去掉中文日期的`日`
			let array = e.split('-');
			if (array.length === 3) {
				let d = array[2];
				return d.startsWith('0') ? d.replaceAll('0', '') : d;
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			let clientWidth = this.$refs.textRef.$el.clientWidth;
			this.menuWidth = clientWidth < 320 ? 320 : clientWidth;
		});
		this.singleTime = this.value ? this.value : '';
	}
}
</script>

<style scoped lang="scss">
.mdi-calendar-month-outline::before {
	color: #999999;
	font-size: 20px;
	padding-top: 2px;
}

::v-deep {
	.v-date-picker-title__date {
		font-size: 22px;
		font-weight: normal;
	}

	.v-date-picker-table--date th {
		padding: 8px 0 12px 0;
	}

	div.v-picker__title.primary {
		background-color: #fff !important;
		color: var(--v-primary-base);
		border-color: #E7E7E7 !important;
		border-bottom: 1px solid;
	}
}
</style>
