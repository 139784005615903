// 'use strict'
export class Methods {
	isEmpty(value) {
		try {
			if (value === null || value === undefined) {
				return true;
			}
			// 判断数字是否是NaN
			if (typeof value === "number") {
				return isNaN(value);
			}
			// 判断参数是否是字符串，去空，如果长度为0则返回true
			if (typeof value === "string") {
				return value.trim().length === 0 || value === "undefined";
			}
			if (typeof value === 'object') {
				// 判断参数是否是数组，数组为空则返回true
				if (value instanceof Array) {
					return value.length === 0;
				}
				// 判断参数是否是对象，判断是否是空对象，是则返回true
				if (value instanceof Object) {
					// 判断对象属性个数
					return Object.getOwnPropertyNames(value).length === 0;
				}
			}
		} catch (e) {
			console.log(e);
			return false;
		}
	}
}

// 判断字符串是否含有大写字母
export function hasCapita(str) {
	var result = str.match(/^.*[A-Z]+.*$/);
    if(result==null) return false;
    return true;
}


let methods = new Methods()
export {methods as met};
