export default {
    props: {
        // 用来配置表单生成设计样式组件的vnode数组
        deploy: {
            type: Object,
            default: () => ({
                // // 用来渲染vnode数组，直观看懂属性名用row
                // row: [
                //     {
                //         cols: "6", // 行宽
                //         subassembly: "", // 表单组件名
                //         global: false, // 增加一个字段表示组件是否是一个全局组件，水平有限，先这样设计
                //         // 属性名，根据属性名，赋值到对象对应的属性上
                //         property: '',
                //         connector: {
                //             // 在props中创建的属性，会转发赋值到生成组件的接口中
                //             props: {

                //             },
                //             // 赋值在组件$el静态自定义属性上
                //             attrs: {

                //             },
                //             // 在on对象中创建的属性会转发到生成组件事件api中
                //             on: {
                //                 // 如果使用的是普通函数，会在绑定的时候拷贝过程中，失去this
                //                 // 如果想在事件触发的时候，使用this数据，使用箭头函数，可以使用当前声明对象的环境也就是父组件的this
                //             },
                //             // 这个不用多描述
                //             class: {

                //             },
                //             // dom接口对象
                //             domProps: {
                //                 // 可以使用像innerHTML、innerText
                //             }
                //         },
                //         // 默认值, 如果有此属性，则把值填充进生成的组件value中
                //         default: "",
                //         // 指定当前如果是个组件，而不是原生标签，就根据此属性值把默认值赋值到对应的props对象上的属性
                //         // 如果不创建这个属性，默认值默认插入组件value接口中，与双向绑定指定属性相同
                //         appointValue: "",
                //     }
                // ]
            })
        },
        // 测试deploy的使用对象。
        deploy2: {
            type: Object,
            default: () => ({})
        },
        // 告诉动态渲染组件，不想使用表单对象作为父容器
        cancel_form: {
            type: Boolean,
            default: false
        },
        // 作者开发组件测试使用的，调用者无视
        vmm: {
            type: String,
            default: ""
        }
    }
}