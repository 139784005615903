// 提供给外层组件调用的方法，
export default {
    methods: {
        // 验证函数，它的作用是验证表单的内容，并返回一个验证结果和this.form内容对象
        validate() {
            if (this.$refs.form.validate() === true) {
                return {
                    form: this.form,
                    done: true
                }
            }else {
                return {
                    form: this.form,
                    done: false
                }
            }
        },
        // 重置函数，它的作用是清空this.form上的所有属性值，清理全部渲染的组件value
        reset() {
            try {
                for (const key in this.form) {
                    this.form[key] = '';
                }
                this.myKey++;
                this.myKey2 = this.myKey;
                return true;
            } catch (error) {
                return error;
            }
        },
        // 提供给关闭表单时候一起调用的，他只是清理form
        empty() {
            // 这里先清理form，考虑到render函数重新渲染的能力可能不需要
            // 再多个清理渲染组件value操作
            try {
                this.form = {};
            } catch (error) {
                return error
            }
        },
        // 提供给调用者组件查看this.form当前信息
        formValue() {
            return this.form;
        },
        // 类型检查方法
        // 检查有没有允许的类型，因为这里默认不让undefined、null赋值上去，
        // 为了保证后台没有误接受值
        typeCheck(obj, name) {
            // 判断类型变量是否存在，存在就判断原型
            // if (type && value instanceof type) {
            //     return true;
            // } else if (typeof value === 'undefined') {
            //     return false;
            // }
            // if (!type && JSON.stringify(value) === undefined) {
            //     return false;
            // } else if (type && JSON.stringify(value) !== type) {
            //     return false;
            // }
            return name in obj;
        }
    },
    mounted() {
        this.$emit('getRefs',this.$refs);
    }
}