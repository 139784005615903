export default {
	methods: {
		search(form) {
			this.$emit("search", form);
		},
		remove(item) {
			this.$emit("remove", item.sqlid);
		},
		// 编辑前的操作
		openEditDialog(form) {
			this.formTitle = '编辑' + this.pageTitle;
			this.beforeOpenFormDialog = true;
			this.$emit("edit", form.sqlid, () => {
				this.beforeOpenFormDialog = false;
				this.formDialog = true;
			}, () => {
				this.beforeOpenFormDialog = false
			}, false);
		},
		// 保存
		validateSave(item, callback) {
			if (item.done) {
				this.$emit("save", item.form, callback);
			}
		},
		// 复制前的操作
		openCopyDialog(form, removePropertyArray, enabledProperty) {
			this.formTitle = '新建' + this.pageTitle;
			this.beforeOpenFormDialog = true;
			this.$emit("edit", form.sqlid, () => {
				for (const obj of this.listDeploy.row) {
					if (removePropertyArray.includes(obj.property)) {
						delete obj.default;
						if (obj.connector?.props) {
							delete obj.connector.props.value;
						}
					}
					if (obj.property === enabledProperty) {
						obj.connector.props.disabled = false;
					}
				}
				this.beforeOpenFormDialog = false;
				this.formDialog = true;
			}, () => {
				this.beforeOpenFormDialog = false
			}, true);
		},
	}
}
