export default {
    props: {
        // 声明lable显示位置方向
        direction: {
            type: String,
            default: "left"
        },
        // 标签
        label: {
            type: String,
            default: "状态"
        },
        // 激活状态颜色
        color: {
            type: String,
            default: "primary"
        },
        // 初始值
        value: {
            type: Boolean | Number,
            default: false
        },
        // 放大，不过很难看
        inset: {
            type: Boolean,
            default: true
        },
        // 隐藏错误和提示
        hideDetails: {
            type: Boolean,
            default: false
        },
        // 压低高度
        dense: {
            type: Boolean,
            default: true
        },
        // 扩展接口: 在这个对象下可以再次定义props属性，不过这个props接口属性是直接添加在ui组件上的
        // 没有经过特殊处理
        extension: {
            type: Object,
            default: ()=> ({})
        },
        // 权限code
        rightCode: {
            type: String,
            default: ""
        }
    }
}