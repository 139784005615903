<template>
  <v-autocomplete
      :label="label"
      @keydown="startMemorys"
      @change="modification"
      :value="values"
      :items="citems"
      :clearable="clearable"
      :item-text="itemText"
      :item-value="itemValue"
      :dense="dense"
      :outlined="outlined"
      :placeholder="placeholder"
      :itemColor="itemColor"
      :hide-details="hideDetails"
      :disabled="disabled"
      :no-data-text="noDataText"
      :multiple="multiple"
      v-bind="additional"
      :required="required"
      :chips="small"
      :rules="rules"
      :small-chips="small"
      :hint="hint"
      :persistent-hint="persistentHint"
      :readonly="readonly"
      :filter="customFilter"
      @blur="blur"
  >
    <!-- 多选模式启用 -->
    <template v-slot:selection="{ item, index }" v-if="multiple">
      <!-- 如果后期需要扩展多选显示功能，这个parent是关键 -->
      <v-chip small v-if="index === 0">
        {{ item[itemText] }}
      </v-chip>
      <v-chip small label v-if="index === 1"> +{{ values.length - 1 }}</v-chip>
    </template>
    <template v-slot:prepend-item v-if="multiple && citems.length > 0">
      <v-checkbox
          :value="checkboxStatus"
          color="primary"
          label="全选"
          class="pl-4"
          @change="checkAll"
          :indeterminate="!!indeterminate"/>
    </template>
  </v-autocomplete>
</template>

<script>
import props from "./w-autocomplete-props";

export default {
  mixins: [props],
  data() {
    return {
      current: "",
      pitch: [],
      searchMemorys: [],
      indeterminate: false,
      checkboxStatus: false
    };
  },
  computed: {
    values() {
      if (this.current instanceof Array) {
        return this.current;
      }
      // this.multiple? this.current.split(','):this.current
      return this.multiple
          ? this.current
              ? this.current.split(",")
              : []
          : this.current;
    },
    small() {
      let values = "";
      if (this.values instanceof Array) {
        // 因为类型是数组，同时避免数组中有错误的空字符串，执行归约操作
        // reduction=默认值是第二个参数""
        values = this.values.reduce((reduction, element) => {
          return reduction + element;
        }, "");
      } else {
        values = this.values;
      }
      return !!(this.chips && values);
    },
    citems() {
      if (!(this.values instanceof Array) && this.values) {
        if (
            this.items.find((obj) => {
              return obj.code === this.value;
            })
        ) {
          return this.items;
        } else {
          // return [...this.items,{code: this.value,desc: '该数据原始数组已移除它，请重新选择'}]
          return [...this.items]
        }
      } else {
        return this.items;
        // return [{ header: 'Group 1' },...this.items];
      }
    },
  },
  methods: {
    p(v) {
      console.log(v);
    },
    blur(event) {
      let target = event.target;
      if (!target._value) {
        this.searchMemorys = [];
      }
    },
    checkAll(v) {
      if (this.searchMemorys.length > 0) {
        this.current = v ? this.searchMemorys : []
      } else {
        this.current = v ? this.citems : []
      }
      let items = []
      this.current.forEach(item => {
        items.push(item[this.itemValue])
      })
      this.modification(items)
    },
    startMemorys(v) {
      this.searchMemorys = []
    },
    customFilter(item, queryText, itemText) {
      let result;
      if (new RegExp(queryText).test(item[this.itemValue]) || new RegExp(queryText).test(item[this.itemText])) {
        this.searchMemorys.push(item);
        result = true;
      } else {
        result = false;
      }
      return result;
    },
    // 同名传出vuetify系列组件的change事件
    modification(v, acceptability = true) {
      if (acceptability) {
        this.current = v;
      }
      if (this.multiple) {
        // 判断返回数组类型是否格式化
        this.pitch = v;
        this.$emit("change", (this.arrayType || !Array.isArray(v)) ? v : v && v.join());
        this.indeterminate = v && v.length > 0 && this.citems.length > v.length;
        this.checkboxStatus = v && v.length > 0 && this.citems.length === v.length;
				console.log((this.arrayType || !Array.isArray(v)) ? v : v && v.join(), 9999)
      } else {
        this.$emit("change", v || v === 0 ? v: '');
      }
    },
    // 查询数组中符合指定属性属性值的对象返回
    inquire(val) {
      let obj = this.items.find((obj) => obj[this.someToValue] === val);
      return obj && obj[this.itemValue];
    },
    // 处理指定属性，同时又是开启多选下拉框模式
    someToValExtraHandle(current) {
      let currents = JSON.parse(JSON.stringify(current));
      let len = currents.length;
      // 判断是不是空数组
      if (len > 0) {
        let arrs = [];
        for (const obj of this.items) {
          for (let i = 0; i < len; i++) {
            if (obj[this.someToValue] === currents[i]) {
              arrs.push(obj);
              currents.splice(i, 1);
              len--;
            }
          }
        }
        return arrs;
      }
      return [];
    },
  },
  watch: {
    value: {
      handler(val) {
        // 判断有没有指定某个属性的属性值作为原来使用code筛选
        if (this.someToValue) {
          // 判断是不是需要多选处理
          if (this.multiple) {
            let currents = val instanceof Array ? val : val.split(",");
            // 调用指定属性处理接口配套的额外的数组处理

            this.current = this.someToValExtraHandle(
                currents[0] ? currents : []
            );
            Promise.resolve().then(() => {
              this.modification(
                  this.current.map((obj) => {
                    return obj[this.itemValue];
                  }),
                  false
              );
            });
          } else {
            // 单选处理
            // 获得查询结果
            this.current = this.inquire(val);

            // 使用异步调用modification()触发change赋值，覆盖掉初始化的this.form[property]
            // = value;
            Promise.resolve().then(() => {
              this.modification(this.current, false);
            });
          }
        } else {
          this.current = val;
          this.pitch = val;
        }
        this.modification(val)
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-list-item__action:first-child {
    margin-right: 16px !important;
  }

  .v-input--selection-controls__input {
    margin-right: 14px !important;
  }

  .v-input--selection-controls__input .v-icon {
    color: var(--v-primary-base);
  }
}
</style>
