<template>
	<v-menu
			ref="menu"
			v-model="menu"
			:close-on-content-click="false"
			:return-value.sync="rangeTime"
			transition="scale-transition"
			offset-y
			:max-width="menuWidth"
			:nudge-top="nudgeTop ? nudgeTop: (hideDetails ? '' : '20px')"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
					ref="textRef"
					:hide-details="hideDetails"
					:dense="dense"
					v-model="rangeTimeText"
					:label="label"
					readonly
					@change="change"
					v-bind="attrs"
					:clearable="clearable"
					@click:clear="clear"
					v-on="on"
					:class="[clazz, {'asterisk': required}]"
					:rules="required ?[v => !!v || (label + '不能为空')]: []"
			>
				<template v-slot:append>
					<v-icon>
						mdi-calendar-month-outline
					</v-icon>
				</template>
			</v-text-field>
		</template>
		<v-date-picker
				ampm-in-title
				range
				v-if="menu"
				v-model="rangeTime"
				full-width
				color="primary"
				locale="zh-cn"
				:day-format="dayFormat"
				@change="$refs.menu.save(rangeTime)"
				:allowed-dates="allowedDatesFunc"
		></v-date-picker>
	</v-menu>
</template>

<script>
import moment from "moment";

export default {
	name: 'rangeTime',
	props: {
		label: {
			type: String,
			default: () => '开始时间 ~ 结束时间'
		},
		dense: {
			type: Boolean,
			default: () => false
		},
		hideDetails: {
			type: Boolean,
			default: false
		},
		clazz: {
			type: String,
			default: ''
		},
		value: {
			type: String,
			default: ''
		},
		nudgeTop: {
			type: String,
			default: ''
		},
		clearable: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		allowedDates: {
			type: Array,
			default: () => []
		},
		notAllowedYesterday: {
			type: Boolean,
			default: false
		},
		notAllowedTodayBefore: {
			type: Boolean,
			default: false
		},
		recent30day: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			rangeTime: [],
			menu: false,
			rangeTimeText: '',
			menuWidth: ''
		}
	},
	model: {
		event: 'change'
	},
	watch: {
		rangeTime(array) {
			if (array && array.length === 2) {
				const a = array[0]
				const b = array[1]
				array = []
				if (moment(b).isBefore(moment(a))) {
					array.push(b)
					array.push(a)
				} else {
					array.push(a)
					array.push(b)
				}
				this.rangeTimeText = array.join(' ~ ');
				this.$emit("input", this.rangeTimeText);
				this.$emit("change", this.rangeTimeText);
			}
		},
		value: {
			handler(v) {
				let value = v;
				if (value && !value.includes('undefined')) {
					this.rangeTimeText = value;
					this.rangeTime = value.split(" ~ ");
				}
			},
			immediate: true
		}
	},
	methods: {
		clear() {
			this.$emit("input", '')
			this.$emit("change", '')
		},
		dayFormat(e) {
			// 去掉中文日期的`日`
			let array = e.split('-');
			if (array.length === 3) {
				let d = array[2];
				return d.startsWith('0') ? d.replaceAll('0', '') : d;
			}
		},
		allowedDatesFunc(val) {
			if (this.notAllowedYesterday) {
				return (new Date() - new Date(val)) > 0;
			} else if (this.notAllowedTodayBefore) {
				return (new Date(val + ' 23:59:59') - new Date()) >= 0;
			} else if (this.allowedDates?.length > 0) {
				return this.allowedDates.includes(val);
			} else if (this.recent30day) {
				const lastThirtyDays = [...new Array(30)].map((i, idx) => moment().startOf("day").subtract(idx, "days").format('YYYY-MM-DD'));
				return lastThirtyDays.includes(val)
			} else {
				return true;
			}
		},
		change() {
			this.$emit("change", '')
		}
	},
	mounted() {
		this.$nextTick(() => {
			let clientWidth = this.$refs.textRef.$el.clientWidth;
			this.menuWidth = clientWidth < 320 ? 320 : clientWidth;
		});
	},
}
</script>

<style scoped lang="scss">
.mdi-calendar-month-outline::before {
	color: #999999;
	font-size: 20px;
	padding-top: 2px;
}

::v-deep {
	.v-date-picker-title__date {
		font-size: 22px;
		font-weight: normal;
	}

	.v-date-picker-table--date th {
		padding: 8px 0 12px 0;
	}

	div.v-picker__title.primary {
		background-color: #fff !important;
		color: var(--v-primary-base);
		border-color: #E7E7E7 !important;
		border-bottom: 1px solid;
	}
}

.v-input {
	//width: 220px !important;
}
</style>
