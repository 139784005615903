<template>
	<div style="display: inline-block">
		<div class="d-flex justify-space-between">
			<template v-for="(config, index) in leftConfig">
				<template v-if="config.type === 'input'">
					<v-text-field
							autocomplete="off"
							hide-details
							dense
							:style="{width: config.width}"
							class="mr-2"
							:label="config.label"
							v-model="form[config.property]"
							:key="index">
					</v-text-field>
				</template>
				<template v-else-if="config.type === 'select'">
					<w-autocomplete
							dense
							hide-details
							class="mr-2"
							:key="index"
							:multiple="config.multiple"
							:items="config.items"
							:label="config.label"
							append-icon="mdi-chevron-down"
							@change="selectChange(config.property, $event)"
					></w-autocomplete>
				</template>
				<!-- 日期范围 -->
				<template v-else-if="config.type === 'rangeTime'">
					<range-time
							hide-details
							v-model="form[config.property]"
							dense
							clazz="mr-2"
							:label="config.label"
							:clearable="true"
							:key="index"
					/>
				</template>
				<template v-else-if="config.type === 'singleTime'">
					<single-time
							hide-details
							v-model="form[config.property]"
							dense
							clazz="mr-2"
							:label="config.label"
							:key="index"
							:clearable="true"
					/>
				</template>
			</template>
			<v-btn depressed color="primary" @click="search" v-if="showBtn">
				<v-icon size="16" class="iconfont mr-1">iconfont icon-sousuo</v-icon>
				查询
			</v-btn>
		</div>
	</div>
</template>

<script>
import rangeTime from "@/components/dateTime/rangeTime.vue";
import singleTime from "@/components/dateTime/singleTime.vue";
import wAutocomplete from '@/components/w-autocomplete/w-autocomplete.vue';

export default {
	components: {rangeTime, singleTime, wAutocomplete},
	name: "leftBtn",
	data() {
		return {
			form: {}
		}
	},
	props: {
		leftConfig: {
			type: Array,
			default: () => []
		},
		showBtn: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		search() {
			let obj = this.form;
			let o = {};
			for (const key in obj) {
				if (obj[key] !== "") {
					o[key] = obj[key];
				}
			}
			this.$emit("search", o);
		},
		selectChange(key, v) {
			this.form[key] = v;
			if (v && !this.showBtn) {
				this.search()
			}
		}
	},
	watch: {
		form: {
			handler(v) {
				console.log(v)
				if (!this.showBtn) {
					this.search()
				}
			},
			deep: true
		}
	}
}
</script>

<style scoped lang="scss">

::v-deep {

	.v-text-field input {
		font-size: 14px;
	}

	.mdi-chevron-down::before {
		color: #DEE2EE;
		font-size: 20px;
	}
}
</style>
