
export default {
    props: {
        // 激活弹窗, 要提供sync
        dialog: {
            type: Boolean,
            default: false
        },
        // 标题名
        title: {
            type: String,
            default: '详情'
        },
        // box宽度
        width: {
            type: Number,
            default: 650
        },
        // 内容高度
        contentHeight: {
            type: Number,
            default: 483
        },
        // 使用方式
        // (1)自定义插槽=custom, (2)选项卡=tabs
        usageMode: {
            type: String,
            default: "custom"
        },
        // 使用选项卡模式，使用这个来为配置
        items: {
            type: Array,
            default: ()=> [
                // {
                //     // 选项卡使用的标题名
                //     title: '订单',
                //     mainbody: {
                //         // 选择加载的组件, 内置迁移进去的组件可以使用名称调用
                //         // 例如：表单=form, 表格=table, 自定义=import('../组件.vue')
                //         module: '',
                //         // 组件props或者domProps
                //         binds: {
                //         }
                //     }
                // },
            ]
        },
        bgColor: {
            type: String,
            default: 'backdropGrey'
        }
    }
}
