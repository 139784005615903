<template>
	<div style="position: relative">
		<!-- 若不需要下面的按钮，则自定义html -->
		<div ref="customHtmlRef">
			<template v-if="customHtml">
				<slot name="customHtml"></slot>
			</template>
		</div>
		<div
				ref="searchRef"
				:class="[normalPage ? 'cToolbarPagination': 'cToolbar']"
				v-if="leftConfig.length > 0 || rightConfig.length > 0">
			<template v-if="leftConfig.length > 0">
				<left-btn ref="leftBtn" :leftConfig="leftConfig" :show-btn="showLeftSearchBtn" @search="search"></left-btn>
			</template>
			<template v-if="rightConfig.length > 0">
				<right-btn :rightConfig="rightConfig" @operate="operate"></right-btn>
			</template>
			<div style="clear: both;"></div>
		</div>
		<v-data-table
				loading-text="数据加载中，请稍后......"
				:loading="loading"
				:headers="headers"
				:items="desserts"
				:items-per-page="showPagination ? pageObject.itemsPerPage : 999"
				class="elevation-1"
				hide-default-footer
				ref="dataTable"
				fixed-header
				:height="fixedHeight || tableHeight"
				:disable-sort=true
				:show-select="showSelect"
				@toggle-select-all="toggleSelectAll"
				v-model="selectItems"
				item-key="sqlid"
		>
			<!-- 无数据插槽 -->
			<template v-slot:no-data>
				<div style="position: absolute; top: 50%; transform: translateY(-50%) translateX(-50%); left: 50%;">
					<v-img src="@/assets/error/no-data.svg" width="361" height="225" style="text-align: center"></v-img>
					<span style="color: #999999; font-size: 18px;">目前还没有数据哦</span>
				</div>
			</template>
			<!-- 表格内容插槽 -->
			<template v-slot:item="{ item, isSelected }">
				<tr>
					<template v-if="showSelect">
						<td>
							<v-checkbox hide-details :input-value="isSelected" @change="changeItem($event, item)"/>
						</td>
					</template>
					<template v-for="(field, index) in headers">
						<!-- 定义插槽格式化特殊展示的字段 -->
						<template v-if="itemScope.includes(field.value)">
							<td :key="field + '_' + index">
								<slot :name="field.value" :item="item"></slot>
							</td>
						</template>
						<!-- 定义表格的按钮（编辑、删除） -->
						<template v-else-if="field.value === 'actions' && btnAction">
							<td :key="field + '_' + index">
								<template v-for="(e, i) in btnActionEvent">
									<v-tooltip bottom :key="e.event + '_' + i" :open-delay="300">
										<template v-slot:activator="{ on, attrs }">
											<v-icon
													v-bind="attrs"
													v-on="on"
													:color="e.color"
													dense
													size="24"
													class="iconfont mr-4"
													:class="e.class"
													:disabled="(e.hasDisableAttribute && item.hasDisableAttribute)/*  || !operatePermissions.includes(e.rightCode)*/"
													@click="operateItem(e.event, item)">
												iconfont icon-{{ e.icon }}
											</v-icon>
										</template>
										<span>{{ e.tooltip }}</span>
									</v-tooltip>
								</template>
							</td>
						</template>
						<!-- 通用展示 -->
						<template v-else>
							<td :key="field + '_' + index">
								<div :style="{width: field.width}" style="text-overflow: ellipsis; overflow: hidden">
									<v-tooltip :open-delay="100" bottom :disabled="!field.width">
										<template v-slot:activator="{ on, attrs }">
											<!-- 表格内容可编辑 -->
											<template v-if="field.editable">
												<v-edit-dialog
														:return-value.sync="item[field.value]"
														@save="saveTableFiled(field.value, item[field.value], item)"
												>
													<span v-bind="attrs" v-on="on" style="color: #5B83FD">{{ item[field.value] }}</span>
													<template v-slot:input>
														<v-text-field
																v-model="item[field.value]"
																single-line
																counter
														></v-text-field>
													</template>
												</v-edit-dialog>
											</template>
											<template v-else>
												<span v-bind="attrs" v-on="on">{{ item[field.value] }}</span>
											</template>
										</template>
										<span>{{ item[field.value] }}</span>
									</v-tooltip>
								</div>
							</td>
						</template>
					</template>
				</tr>
			</template>
		</v-data-table>
		<template v-if="showPagination">
			<pagination :pageObject="pageObject" :pageSizeArray="pageSizeArray"/>
		</template>

		<form-menu
				ref="formRef"
				:key="formKey"
				:listDeploy="listDeploy"
				:dialog.sync="formDialog"
				:title="formTitle"
				@validateSave="validateSave"/>

		<template v-if="beforeOpenFormDialog">
			<v-skeleton-loader
					class="mx-auto"
					light
					type="date-picker-options, table, article, table-tfoot"
					min-height="100vh"
			></v-skeleton-loader>
		</template>
	</div>
</template>

<script>
import props from "./commonTable-props";
import leftBtn from "@/components/topBtn/leftBtn/leftBtn";
import rightBtn from "@/components/topBtn/rightBtn/rightBtn";
import pagination from "@/components/pagination/pagination";
import formMenu from "@/components/form-menu/form-menu.vue";
import commonTableMethods from "./commonTable-methods";

export default {
	props,
	mixins: [commonTableMethods],
	components: {
		leftBtn,
		rightBtn,
		pagination,
		formMenu
	},
	data() {
		return {
			// 每页展示条数集合
			pageSizeArray: [5, 10, 20, 50, 100],
			pageObject: {},
			desserts: [],
			formDialog: false,
			formTitle: '',
			formKey: 0,
			tableHeight: '',
			selectItems: [],
			beforeOpenFormDialog: false,
			operatePermissions: []
		}
	},
	methods: {
		// 表格的操作位置事件集合
		operateItem(event, item) {
			// 强制失去焦点，避免关闭模态框后继续触发提示操作
			document.activeElement?.blur();
			if (event === "deleteItem") {
				let content = '确定删除' + this.pageTitle + item[this.pageKey] + '吗';
				this.confirm.showConfirm(content).then(() => {
					this.remove(item);
				}).catch(() => {
				});
			} else if (event === "editItem") {
				this.openEditDialog(item);
			} else {
				// 其他表格操作
				this.$emit('otherTableOperate', event, item);
			}
		},
		// 表格顶部右侧按钮事件
		operate(event) {
			if (event === 'add') {
				this.formTitle = '新建' + this.pageTitle;
				// 初始化
				this.listDeploy.sqlid = 0;
				for (const obj of this.listDeploy.row) {
					if (obj.subassembly !== 'div' && !obj.disabledDeleteDefault) {
						delete obj.default;
					}
					if (obj.hasEditDisabledProperty) {
						obj.connector.props.disabled = false
					}
					if (obj.connector?.props) {
						delete obj.connector.props.value;
						if (obj.subassembly === 'WFileinput') {
							delete obj.connector.props.items;
						}
					}
				}
				this.formDialog = true;
			} else {
				this.$emit("otherRightOperate", event);
			}
		},
		// 计算表格的高度
		getTableHeight() {
			if (!this.dialogTable) {
				this.$nextTick(() => {
					const clientHeight = document.documentElement.clientHeight;
					const headerHeight = 56;
					const paginationHeight = 48;
					const spacing = 12 * 2 + 10;
					const searchHeight = (this.$refs.searchRef ? this.$refs.searchRef.clientHeight : 0) + this.$refs.customHtmlRef.clientHeight;
					this.tableHeight = clientHeight - headerHeight - paginationHeight - spacing - searchHeight;
				});
			}
		},
		toggleSelectAll(item) {
			this.selectItems = item.value ? item.items : [];
			this.$emit('getSelectItems', this.selectItems);
		},
		changeItem(event, item) {
			if (event) {
				this.selectItems.push(item);
			} else {
				let selectItems = [];
				this.selectItems.forEach((i) => {
					if (item.sqlid !== i.sqlid) {
						selectItems.push(i);
					}
				});
				this.selectItems = selectItems;
			}
			this.$emit('getSelectItems', this.selectItems);
		},
		// 可编辑的表格内容保存
		saveTableFiled(field, value, item) {
			this.$emit('saveTableFiled', field, value, item);
		}
	},
	watch: {
		listData: {
			handler(data) {
				if (data && data.records) {
					this.desserts = data.records;
					if (this.showPagination) {
						this.pageObject = Object.assign(
								{},
								{
									currentPage: data.currentpage,
									itemsPerPage: data.pagesize,
									total: data.total,
									totalPage: data.totalpage,
								}
						);
					}
					if (this.showSelect) {
						this.selectItems = [];
						this.$emit('getSelectItems', []);
					}
				}
			},
			immediate: true
		},
		formDialog: {
			handler(v) {
				if (v) {
					this.$emit("beforeOpenAddDialog", new RegExp("新建").test(this.formTitle) ? 'add' : 'edit');
					this.formKey++;
				}
			}
		}
	},
	mounted() {
		this.getTableHeight();
		window.addEventListener("resize", this.getTableHeight);
		let str = sessionStorage.getItem('operatePermissionKey');
		if (str) {
			this.operatePermissions = JSON.parse(str);
		}
		// 全局esc关闭弹窗
		document.addEventListener('keyup', (e) => {
			if (e.keyCode === 27 && this.formDialog) {
				this.formDialog = false
			}
		})
	},
	destroyed() {
		window.removeEventListener("resize", this.getTableHeight);
		window.removeEventListener("keyup", () => {
		});
	}
}
</script>

<style lang="scss" scoped>
::v-deep {
	/* v-data-table表格样式修改 begin */
	.v-data-table__wrapper {

		table > tbody {
			tr > td {
				border-bottom: none !important;
				color: #333333;
				font-size: 14px !important;
				height: 48px;
				line-height: 48px;
			}

			tr:hover {
				background-color: #ffffff !important;
				box-shadow: 0 2px 10px 0 rgba(51, 51, 51, 0.15);
			}

			.v-data-table__empty-wrapper:hover {
				box-shadow: unset;
			}
		}

	}

	.v-data-table-header {

		tr > th:last-child {
			border-radius: 0 4px 0 0;
		}

		tr > th:first-child {
			border-radius: 4px 0 0 0;
		}

		tr > th {

			color: #ffffff !important;
			font-weight: bold;
			font-size: 14px !important;
			white-space: nowrap;

			.mdi-checkbox-blank-outline::before {
				border-color: #e2e2e2;
			}

			.mdi-checkbox-marked::before, .mdi-minus-box::before {
				color: #e2e2e2;
			}
		}
	}

	.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
		background: var(--v-primary-base);
	}

	.v-input--selection-controls {
		padding-top: 0 !important;
		margin-top: 0 !important;
		position: relative;
	}

	.v-data-table__wrapper table > tbody tr > td {
		color: #666666;
		white-space: nowrap;
	}

	/* v-data-table表格样式修改 end */

	.v-skeleton-loader--is-loading {
		position: fixed;
		top: 0;
		right: 0;
		width: 50%;
		background: var(--v-backdropGrey-base);
		z-index: 9;
	}

}

.cToolbarPagination {
	padding: 20px 20px 10px 20px;
	background-color: #ffffff;
	border-radius: 4px;
	margin-bottom: 10px;
}

.cToolbar {
	padding-bottom: 8px;
}
</style>
