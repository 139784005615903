<template>
	<div class="mb-4">
		<div v-if="title" :class="required ? 'asterisk-div': ''" class="c-one-sub-title">{{ title }}</div>
		<div v-if="subTitle" style="font-size: 12px; color: #999999;">
			{{ subTitle }}
		</div>
		<div class="d-flex flex-wrap">
			<draggable :list="imageList" class="d-flex flex-wrap">
				<div
						v-for="(item, index) in imageList"
						:key="index">
					<div v-if="hasInput" style="width: 120px; margin-top: 12px">
						<v-text-field :label="label + (index + 1)" hide-details v-model="item.text"/>
					</div>
					<div
							class="mr-3 d-image-container mt-3"
							style="width: 120px; height: 144px; border: 1px solid #D8D8D8; text-align: center; border-radius: 4px; position: relative">
						<div
								v-if="imageList.length > 1 || (imageList.length === 1 && imageList[0].url)"
								class="close-icon"
								@click="removeList(item.id)"
								style="position: absolute; right: -10px; top: -13px; cursor: pointer; display: none">
							<v-icon size="32" class="iconfont" color="#000000">iconfont icon-guanbi-fill</v-icon>
						</div>
						<div class="mt-2" v-if="item.url" style="position: relative">
							<div
									v-if="remark === 3"
									style="width: 100px; height: 100px; border-radius: 4px; background: #D8D8D8; position: relative; display: inline-block">
								<v-icon size="36" class="iconfont box-center">iconfont icon-yinpin-fill</v-icon>
							</div>
							<img
									v-else
									:src="remark === 2 ? item.url + '?x-oss-process=video/snapshot,t_1000,m_fast': item.url"
									style="width: 100px; height: 100px; border-radius: 4px; object-fit: cover" alt="">
							<div class="text-bg" @click="addMaterial(item)">
								更换{{ remark_map[remark] }}
							</div>
						</div>
						<div
								v-else
								class="mt-5 ml-2"
								style="width: 100px; height: 100px; background: #F5F8FF; border-radius: 4px; position: relative;">
							<div class="box-center" style="cursor: pointer" @click="addMaterial(item)">
								<v-icon color="primary" class="iconfont">iconfont icon-zengjia</v-icon>
								<div
										class="mt-3" :style="{color: 'var(--v-primary-base)'}"
										style="font-size: 12px; white-space: nowrap">
									添加{{ remark_map[remark] }}
								</div>
							</div>
						</div>
						<div
								v-if="item.url"
								style="font-size: 12px; cursor: pointer"
								:style="{color: 'var(--v-primary-base)'}"
								@click="copy(item.url)">
							复制{{ remark_map[remark] }}路径
						</div>
					</div>
				</div>
			</draggable>
			<div v-if="showAddIcon" style="position: relative; width: 32px; height: 144px" class="ml-8">
				<v-btn
						class="box-center"
						depressed
						color="primary"
						style="min-width: 32px; width: 32px; height: 32px"
						@click="addNullImage">
					<v-icon color="#ffffff" class="iconfont" size="16">iconfont icon-zengjia</v-icon>
				</v-btn>
			</div>
			<exhibition-data-box
					:dialog.sync="dialog" :width="1024" :title="'添加' + remark_map[remark]" :contentHeight="600"
					bgColor="#ffffff">
				<common-material
						:multiple="false"
						:remark="remark"
						:bouncedMode="true"
						:imageWidth="120"
						:leftWidth="160"
						:accept="accept"
						@change="imageUrl = $event[0]"/>
				<template v-slot:footer>
					<div class="text-center">
						<v-btn depressed color="primary" outlined @click="dialog = false">取消</v-btn>
						<v-btn depressed color="primary" class="ml-4" @click="change">确定</v-btn>
					</div>
				</template>
			</exhibition-data-box>
		</div>
	</div>
</template>

<script>
import commonMaterial from "@/components/common-material/index.vue"
import exhibitionDataBox from "@/components/exhibition-data-box/exhibition-data-box"
import draggable from 'vuedraggable';

export default {
	components: {
		commonMaterial,
		exhibitionDataBox,
		draggable
	},
	props: {
		hasInput: {
			type: Boolean,
			default: false
		},
		label: {
			type: String,
			default: '颜色'
		},
		limit: {
			type: Number,
			default: 6
		},
		valueText: String,
		valueImage: String,
		value: String,
		showAddIcon: {
			type: Boolean,
			default: true
		},
		required: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: ''
		},
		subTitle: String,
		accept: {
			type: Array,
			default: () => []
		},
		remark: {
			type: Number,
			default: 1
		}
	},
	data() {
		return {
			dialog: false,
			imageList: [{
				url: '',
				id: 0,
				text: ''
			}],
			id: 0,
			selectItem: {},
			imageUrl: '',
			remark_map: {
				1: '图像',
				2: '视频',
				3: '音频',
			}
		}
	},
	methods: {
		removeList(i) {
			let list = []
			this.imageList.forEach(item => {
				if (i !== item.id) {
					list.push(item)
				}
			})
			if (list.length === 0) {
				list.push({
					url: '',
					id: 0,
					text: ''
				})
			}
			this.imageList = list
		},
		copy(message) {
			let input = document.createElement('input')
			input.value = message;
			document.body.appendChild(input)
			input.select()
			document.execCommand('copy')
			this.snackbar.success('复制成功');
			//移除input标签
			document.body.removeChild(input)
		},
		addMaterial(item) {
			this.dialog = true
			this.selectItem = item
		},
		change() {
			this.selectItem.url = this.imageUrl
			this.dialog = false
		},
		addNullImage() {
			if (this.imageList.length >= this.limit) {
				this.snackbar.error('添加图片已达上限' + this.limit + '张')
			} else {
				this.id++;
				this.imageList.push({url: '', id: this.id, text: ''})
			}
		},
		initValue(v) {
			if (v) {
				let array = []
				v.split(',').forEach((item, index) => {
					let text = ''
					if (this.hasInput && this.valueText) {
						text = this.valueText.split(',')[index]
					}
					array.push({url: item, id: index, text: text})
					this.id = index
				})
				this.imageList = array
			}
		}
	},
	watch: {
		imageList: {
			handler(v) {
				if (v && v.length > 0) {
					let text = [], images = []
					v.forEach(item => {
						if (item.url) {
							images.push(item.url)
						}
						if (item.text && this.hasInput) {
							text.push(item.text)
						}
					})
					this.$emit('change', images.join(','), text.join(','))
					this.$emit('change1', v)
				}
			},
			deep: true
		},
		valueImage: {
			handler(v) {
				this.initValue(v)
			},
			immediate: true
		},
		value: {
			handler(v) {
				this.initValue(v)
			},
			immediate: true
		}
	}
}
</script>

<style scoped lang="scss">
.d-image-container:hover {
	.close-icon {
		display: inline-block !important;
	}
}

.text-bg {
	position: absolute;
	bottom: 6px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 12px;
	color: #ffffff;
	background: rgba(0, 0, 0, .5);
	padding: 5px;
	width: 85%;
	border-radius: 0 0 4px 4px;
	cursor: pointer;
}

.c-one-sub-title {
	font-size: 14px;
	font-weight: bold;
	color: #666666;
	margin: 8px 0;
}
</style>
