export default [
	{
		path: '/login',
		name: 'login',
		meta: {
			name: "登录页"
		},
		component: () => import('@/views/login/login.vue')
	},
	{
		path: '/pc-decoration-details',
		name: 'pcDecorationDetails',
		meta: {
			name: '页面装修'
		},
		component: ()=> import('@/views/pc-web-mange/pc-decoration/pc-decoration-details.vue')
	},
	{
		path: '/',
		name: '',
		component: () => import('@/views/index.vue'),
		children: [
			{
				path: '/blank',
				name: 'blank',
				meta: {
					name: "-",
					type: 'title'
				},
				component: () => import('@/views/common/blank.vue')
			},
			{
				path: '/403',
				name: '403',
				meta: {
					name: "403",
					type: 'title'
				},
				component: () => import('@/views/common/403.vue')
			},
			{
				path: '/404',
				name: '404',
				meta: {
					name: "404",
					type: 'title'
				},
				component: () => import('@/views/common/404.vue')
			},
			{
				path: '/index',
				name: 'index',
				meta: {
					name: "首页",
					type: 'title'
				},
				component: () => import('@/views/index/index.vue')
			},
			/** 页面管理begin */
			{
				path: '/basic-info',
				name: 'basicInfo',
				meta: {
					name: "基础信息",
					type: 'title'
				},
				component: () => import('@/views/web-mange/mobile-terminal/basic-info/basic-info.vue')
			},
			{
				path: '/coumn-manage-one',
				name: 'coumnManageOne',
				meta: {
					name: "一级栏目",
					type: 'tab',
					tab: [
						{title: '一级栏目', path: '/coumn-manage-one'},
						{title: '二级栏目', path: '/coumn-manage-two'},
						{title: '三级栏目', path: '/coumn-manage-three'},
					]
				},
				component: () => import('@/views/web-mange/mobile-terminal/column-manage-one/column-manage-one.vue')
			},
			{
				path: '/coumn-manage-two',
				name: 'coumnManageTwo',
				meta: {
					name: "二级栏目",
					type: 'tab',
					tab: [
						{title: '一级栏目', path: '/coumn-manage-one'},
						{title: '二级栏目', path: '/coumn-manage-two'},
						{title: '三级栏目', path: '/coumn-manage-three'},
					]
				},
				component: () => import('@/views/web-mange/mobile-terminal/column-manage-two/column-manage-two.vue')
			},
			{
				path: '/coumn-manage-three',
				name: 'coumnManageThree',
				meta: {
					name: "三级栏目",
					type: 'tab',
					tab: [
						{title: '一级栏目', path: '/coumn-manage-one'},
						{title: '二级栏目', path: '/coumn-manage-two'},
						{title: '三级栏目', path: '/coumn-manage-three'},
					]
				},
				component: () => import('@/views/web-mange/mobile-terminal/column-manage-three/column-manage-three.vue')
			},
			{
				path: '/content-manage',
				name: 'contentManage',
				meta: {
					name: "内容管理",
					type: 'customTab',
					keyname: 'menus', // 这个属性指定值，会去请求对应的下拉框值
					params: [
						{
							key: "menus",
							value: "M"
						}
					],
					tab: [
						{title: '一级栏目', path: '/coumn-manage-one'},
						{title: '二级栏目', path: '/coumn-manage-two'},
					]
				},
				component: () => import('@/views/web-mange/content-manage/content-manage.vue')
			},
			/** 页面管理end */
			/** 电脑端页面管理begin */
			{
				path: '/pc-basic-info/header-login',
				name: 'headerLogin',
				meta: {
					name: "顶部导航",
					type: 'tab',
					tab: [
						{title: '顶部导航', path: '/pc-basic-info/header-login'},
						{title: '底部导航', path: '/pc-basic-info/footer-navigation'},
						{title: '颜色配置', path: '/pc-basic-info/color-config'},
						{title: '协议条款', path: '/pc-basic-info/terms-agreement'},
						{title: '业态顶图', path: '/pc-basic-info/banner-config'}
					]
				},
				component: () => import('@/views/pc-web-mange/mobile-terminal/basic-info/header-login.vue')
			},
			{
				path: '/pc-basic-info/footer-navigation',
				name: 'footerNavigation',
				meta: {
					name: "底部导航",
					type: 'tab',
					tab: [
						{title: '顶部导航', path: '/pc-basic-info/header-login'},
						{title: '底部导航', path: '/pc-basic-info/footer-navigation'},
						{title: '颜色配置', path: '/pc-basic-info/color-config'},
						{title: '协议条款', path: '/pc-basic-info/terms-agreement'},
						{title: '业态顶图', path: '/pc-basic-info/banner-config'}
					]
				},
				component: () => import('@/views/pc-web-mange/mobile-terminal/basic-info/footer-navigation.vue')
			},
			{
				path: '/pc-basic-info/color-config',
				name: 'colorConfig',
				meta: {
					name: "颜色配置",
					type: 'tab',
					tab: [
						{title: '顶部导航', path: '/pc-basic-info/header-login'},
						{title: '底部导航', path: '/pc-basic-info/footer-navigation'},
						{title: '颜色配置', path: '/pc-basic-info/color-config'},
						{title: '协议条款', path: '/pc-basic-info/terms-agreement'},
						{title: '业态顶图', path: '/pc-basic-info/banner-config'}
					]
				},
				component: () => import('@/views/pc-web-mange/mobile-terminal/basic-info/color-config.vue')
			},
			{
				path: '/pc-basic-info/terms-agreement',
				name: 'termsAgreement',
				meta: {
					name: "协议条款",
					type: 'tab',
					tab: [
						{title: '顶部导航', path: '/pc-basic-info/header-login'},
						{title: '底部导航', path: '/pc-basic-info/footer-navigation'},
						{title: '颜色配置', path: '/pc-basic-info/color-config'},
						{title: '协议条款', path: '/pc-basic-info/terms-agreement'},
						{title: '业态顶图', path: '/pc-basic-info/banner-config'}
					]
				},
				component: () => import('@/views/pc-web-mange/mobile-terminal/basic-info/terms-agreement.vue')
			},
			{
				path: '/pc-basic-info/banner-config',
				name: 'bannerConfig',
				meta: {
					name: "业态顶图",
					type: 'tab',
					tab: [
						{title: '顶部导航', path: '/pc-basic-info/header-login'},
						{title: '底部导航', path: '/pc-basic-info/footer-navigation'},
						{title: '颜色配置', path: '/pc-basic-info/color-config'},
						{title: '协议条款', path: '/pc-basic-info/terms-agreement'},
						{title: '业态顶图', path: '/pc-basic-info/banner-config'}
					]
				},
				component: () => import('@/views/pc-web-mange/mobile-terminal/basic-info/banner-config.vue')
			},
			{
				path: '/pc-coumn-manage-one',
				name: 'coumnManageOne',
				meta: {
					name: "一级栏目",
					type: 'tab',
					tab: [
						{title: '一级栏目', path: '/pc-coumn-manage-one'},
						{title: '二级栏目', path: '/pc-coumn-manage-two'},
						{title: '三级栏目', path: '/pc-coumn-manage-three'},
					]
				},
				component: () => import('@/views/pc-web-mange/mobile-terminal/column-manage-one/column-manage-one.vue')
			},
			{
				path: '/pc-coumn-manage-two',
				name: 'coumnManageTwo',
				meta: {
					name: "二级栏目",
					type: 'tab',
					tab: [
						{title: '一级栏目', path: '/pc-coumn-manage-one'},
						{title: '二级栏目', path: '/pc-coumn-manage-two'},
						{title: '三级栏目', path: '/pc-coumn-manage-three'},
					]
				},
				component: () => import('@/views/pc-web-mange/mobile-terminal/column-manage-two/column-manage-two.vue')
			},
			{
				path: '/pc-coumn-manage-three',
				name: 'coumnManageThree',
				meta: {
					name: "三级栏目",
					type: 'tab',
					tab: [
						{title: '一级栏目', path: '/pc-coumn-manage-one'},
						{title: '二级栏目', path: '/pc-coumn-manage-two'},
						{title: '三级栏目', path: '/pc-coumn-manage-three'},
					]
				},
				component: () => import('@/views/pc-web-mange/mobile-terminal/column-manage-three/column-manage-three.vue')
			},
			{
				path: '/pc-content-manage',
				name: 'pcContentManage',
				meta: {
					name: "内容管理",
					type: 'customTab',
					keyname: 'menus', // 这个属性指定值，会去请求对应的下拉框值
					params: [
						{
							key: "menus",
							value: "PC"
						}
					],
					tab: [
						{title: '一级栏目', path: '/pc-coumn-manage-one'},
						{title: '二级栏目', path: '/pc-coumn-manage-two'},
					]
				},
				component: () => import('@/views/pc-web-mange/content-manage/content-manage.vue')
			},
			{
				path: '/pc-decoration',
				name: 'pcDecoration',
				meta: {
					name: '页面装修',
					type: 'title'
				},
				component: ()=> import('@/views/pc-web-mange/pc-decoration/pc-decoration.vue')
			},
			/** 电脑端页面管理end */
			{
				path: '/material-center',
				name: 'materialCenter',
				meta: {
					name: "素材中心",
					type: 'title'
				},
				component: () => import('@/views/material-center/index.vue')
			},
			/** 产品管理begin **/
			{
				path: '/hotel-manage',
				name: 'hotelManage',
				meta: {
					name: "酒店管理",
					type: 'title'
				},
				component: () => import('@/views/product-manage/hotel-manage/hotel-manage.vue')
			},
			{
				path: '/room-type',
				name: 'roomType',
				meta: {
					name: "房型设置",
					type: 'url',
					title: '返回酒店管理',
					path: '/hotel-manage'
				},
				component: () => import('@/views/product-manage/hotel-manage/roomType.vue')
			},
			{
				path: '/ticket-manage',
				name: 'ticketManage',
				meta: {
					name: "票务管理",
					type: 'title'
				},
				component: () => import('@/views/product-manage/ticket-manage/ticket-manage.vue')
			},
			{
				path: '/ticket-type',
				name: 'ticketType',
				meta: {
					name: "票型设置",
					type: 'url',
					title: '返回票务管理',
					path: '/ticket-manage'
				},
				component: () => import('@/views/product-manage/ticket-manage/ticketType.vue')
			},
			{
				path: '/coupon-manage',
				name: 'couponManage',
				meta: {
					name: "优惠券管理",
					type: 'title'
				},
				component: () => import('@/views/product-manage/coupon-manage/coupon-manage.vue')
			},
			{
				path: '/coupon-type',
				name: 'couponType',
				meta: {
					name: "优惠券小类",
					type: 'url',
					title: '返回优惠券管理',
					path: '/coupon-manage'
				},
				component: () => import('@/views/product-manage/coupon-manage/couponType.vue')
			},
			{
				path: '/map-manage',
				name: 'mapManage',
				meta: {
					name: "业态管理",
					type: 'tab',
					tab: [
						{title: '业态管理', path: '/map-manage'},
						{title: '路线规划', path: '/route-planning'},
					]
				},
				component: () => import('@/views/product-manage/map-manage/map-manage.vue')
			},
			{
				path: '/route-planning',
				name: 'RoutePlanning',
				meta: {
					name: "路线规划",
					type: 'tab',
					tab: [
						{title: '业态管理', path: '/map-manage'},
						{title: '路线规划', path: '/route-planning'},
					]
				},
				component: () => import('@/views/product-manage/map-manage/route-planning.vue')
			},
			{
				path: '/map-marker',
				name: 'mapMarker',
				meta: {
					name: "地图业态规划",
					type: 'url',
					title: '返回地图业态管理',
					path: '/map-manage'
				},
				component: () => import('@/views/product-manage/map-manage/map-marker.vue')
			},
			{
				path: '/setmeal-manage/broad-class',
				name: 'broadclass',
				meta: {
					name: "套餐管理",
					type: 'title'
				},
				component: () => import('@/views/product-manage/setmeal-manage/broad-class/broad-class.vue')
			},
			{
				path: '/setmeal-manage/sub-class',
				name: 'subclass',
				meta: {
					name: "套餐小类",
					type: 'url',
					title: '返回套餐管理',
					path: '/setmeal-manage/broad-class'
				},
				component: () => import('@/views/product-manage/setmeal-manage/sub-class/sub-class.vue')
			},
			{
				path: '/product-parent',
				name: 'productParent',
				meta: {
					name: "景区产品",
					type: 'one-source-tab',
					keyname: 'warestype'
				},
				component: () => import('@/views/product-manage/scenic-product/product-parent.vue')
			},
			{
				path: '/product-children',
				name: 'productChildren',
				meta: {
					name: "产品小类",
					type: 'url',
					title: '返回景区产品',
					path: '/product-parent'
				},
				component: () => import('@/views/product-manage/scenic-product/product-children.vue')
			},
			{
				path: '/meeting-manage',
				name: 'meetingManage',
				meta: {
					name: "会场管理",
					type: 'title'
				},
				component: () => import('@/views/product-manage/meeting-manage/meeting-manage.vue')
			},
			{
				path: '/meeting-place',
				name: 'meetingPlace',
				meta: {
					name: "会议室管理",
					type: 'url',
					title: '返回会场管理',
					path: '/meeting-manage'
				},
				component: () => import('@/views/product-manage/meeting-manage/meetingPlace.vue')
			},
			{
				path: '/rule-manage',
				name: 'ruleManage',
				meta: {
					name: "规则管理",
					type: 'title'
				},
				component: () => import('@/views/product-manage/rule-manage/rule-manage.vue')
			},
			{
				path: '/appointment-manage',
				name: 'appointmentManage',
				meta: {
					name: "预约管理",
					type: 'title'
				},
				component: () => import('@/views/product-manage/appointment-manage/appointment-manage.vue')
			},
			{
				path: '/appointmentPlace',
				name: 'appointmentPlace',
				meta: {
					name: "预约场所",
					type: 'url',
					title: '返回预约管理',
					path: '/appointment-manage'
				},
				component: () => import('@/views/product-manage/appointment-manage/appointmentPlace.vue')
			},
			{
				path: '/product-list',
				name: 'productList',
				meta: {
					name: "商品列表",
					type: 'title'
				},
				component: () => import('@/views/product-manage/hand-greeting/product-list.vue')
			},
			{
				path: '/category-template',
				name: 'categoryTemplate',
				meta: {
					name: "类目管理",
					type: 'title'
				},
				component: () => import('@/views/product-manage/hand-greeting/category-template.vue')
			},
			{
				path: '/freight-setting',
				name: 'freightSetting',
				meta: {
					name: "运费设置",
					type: 'title'
				},
				component: () => import('@/views/product-manage/hand-greeting/freight-setting.vue')
			},
			{
				path: '/product-add',
				name: 'productAdd',
				meta: {
					name: "商品新增",
					type: 'url',
					title: '返回商品列表',
					path: '/product-list'
				},
				component: () => import('@/views/product-manage/hand-greeting/add-product.vue')
			},
			{
				path: '/product-edit',
				name: 'productEdit',
				meta: {
					name: "商品编辑",
					type: 'url',
					title: '返回商品列表',
					path: '/product-list'
				},
				component: () => import('@/views/product-manage/hand-greeting/edit-product.vue')
			},
			/** 产品管理end **/
			/** 订单管理begin **/
			{
				path: '/order-record',
				name: 'orderRecord',
				meta: {
					name: "订单记录",
					type: 'tab',
					tab: [
						{title: '订单记录', path: '/order-record'},
						{title: '历史订单', path: '/order-record-his'}
					]
				},
				component: () => import('@/views/order-manage/order-record/order-record.vue')
			},
			{
				path: '/order-record-his',
				name: 'orderRecordHis',
				meta: {
					name: "历史订单",
					type: 'tab',
					tab: [
						{title: '订单记录', path: '/order-record'},
						{title: '历史订单', path: '/order-record-his'}
					]
				},
				component: () => import('@/views/order-manage/order-record/order-record-his.vue')
			},
			{
				path: '/offline-order',
				name: 'offlineOrder',
				meta: {
					name: "线下订单",
					type: 'title'
				},
				component: () => import('@/views/order-manage/offline-orders/index.vue')
			},
			{
				path: '/synthesize-appointment',
				name: 'synthesizeAppointment',
				meta: {
					name: "综合预约",
					type: 'tab',
					tab: [
						{title: '综合预约', path: '/synthesize-appointment'},
						{title: '会务预约', path: '/appointment-record'}
					]
				},
				component: () => import('@/views/order-manage/appointment-record/synthesize-appointment.vue')
			},
			{
				path: '/appointment-record',
				name: 'appointmentRecord',
				meta: {
					name: "会务预约",
					type: 'tab',
					tab: [
						{title: '综合预约', path: '/synthesize-appointment'},
						{title: '会务预约', path: '/appointment-record'}
					]
				},
				component: () => import('@/views/order-manage/appointment-record/appointment-record.vue')
			},
			{
				path: '/delivery-manage',
				name: 'deliveryManage',
				meta: {
					name: "发货管理",
					type: 'tab',
					tab: [
						{title: '发货管理', path: '/delivery-manage'},
						{title: '退货申请', path: '/return-request'}
					]
				},
				component: () => import('@/views/order-manage/logistics-manage/delivery-manage.vue')
			},
			{
				path: '/return-request',
				name: 'returnRequest',
				meta: {
					name: "退货申请",
					type: 'tab',
					tab: [
						{title: '发货管理', path: '/delivery-manage'},
						{title: '退货申请', path: '/return-request'}
					]
				},
				component: () => import('@/views/order-manage/logistics-manage/return-request.vue')
			},
			{
				path: '/apply-for-refund',
				name: 'applyForRefund',
				meta: {
					name: "退款申请",
					type: 'tab',
					tab: [
						{title: '退款申请', path: '/apply-for-refund'},
						{title: '历史退款', path: '/history-refund'}
					]
				},
				component: () => import('@/views/order-manage/refund-manage/apply-for-refund.vue')
			},
			{
				path: '/history-refund',
				name: 'historyRefund',
				meta: {
					name: "历史退款",
					type: 'tab',
					tab: [
						{title: '退款申请', path: '/apply-for-refund'},
						{title: '历史退款', path: '/history-refund'}
					]
				},
				component: () => import('@/views/order-manage/refund-manage/history-refund.vue')
			},
			{
				path: '/invoice-manage',
				name: 'invoiceManage',
				meta: {
					name: "发票管理",
					type: 'title'
				},
				component: () => import('@/views/order-manage/invoice-manage/invoice-manage.vue')
			},
			{
				path: '/feedback',
				name: 'feedback',
				meta: {
					name: "意见反馈",
					type: 'title'
				},
				component: () => import('@/views/order-manage/feedback/feedback.vue')
			},
			/** 订单管理end **/

			/** 系统管理begin **/
			{
				path: '/user',
				name: 'user',
				meta: {
					name: "用户管理",
					type: 'title'
				},
				component: () => import('@/views/system-manage/user/user.vue')
			},
			{
				path: '/role',
				name: 'role',
				meta: {
					name: "角色管理",
					type: 'title'
				},
				component: () => import('@/views/system-manage/role/role.vue')
			},
			{
				path: '/first-resource',
				name: 'firstResource',
				meta: {
					name: "一级资源",
					type: 'title'
				},
				component: () => import('@/views/system-manage/resources/first.vue')
			},
			{
				path: '/second-resource',
				name: 'secondResource',
				meta: {
					name: "二级资源",
					type: 'tab',
					hasTitle: true,
					tab: ['酒店设施', '房型设施', '会场设施', '会议室设施']
				},
				component: () => import('@/views/system-manage/resources/second.vue')
			},
			{
				path: '/common-setting',
				name: 'commonSetting',
				meta: {
					name: "通用设置",
					type: 'title'
				},
				component: () => import('@/views/system-manage/commonSetting/common-setting.vue')
			},
			{
				path: '/lang-translation',
				name: 'langTranslation',
				meta: {
					name: "多语言翻译",
					type: 'title'
				},
				component: () => import('@/views/system-manage/lang/index.vue')
			},
			{
				path: '/operate-log',
				name: 'operateLog',
				meta: {
					name: "操作日志",
					type: 'title'
				},
				component: () => import('@/views/system-manage/operate-log/operate-log.vue')
			},
			{
				path: '/sys-utils',
				name: 'sysUtils',
				meta: {
					name: "系统工具",
					type: 'title'
				},
				component: () => import('@/views/system-manage/sys-utils/sys-utils.vue')
			},
			{
				path: '/message-notice',
				name: 'messageNotice',
				meta: {
					name: "短信通知",
					type: 'title'
				},
				component: () => import('@/views/system-manage/notice/message-notice.vue')
			},
			{
				path: '/app-notice',
				name: 'appNotice',
				meta: {
					name: "机器人通知",
					type: 'title'
				},
				component: () => import('@/views/system-manage/notice/app-notice.vue')
			},
			{
				path: '/mini-program',
				name: 'miniProgram',
				meta: {
					name: "小程序通知",
					type: 'title'
				},
				component: () => import('@/views/system-manage/notice/mini-program.vue')
			}
			/** 系统管理end **/
		]
	},
]
