export default {
    data() {
        return {
            tt: false,
            count: 0
        }
    },
    methods: {
        pp(v, e) {
            console.log(v, e);
            return v;
        },
        /**
     * 下面的三个方法，是实现url转文件对象方法
     * @imageToBase64 图片转Base64
     * @base64ToFile base64转文件对象
     * @handleImgToBase64 手动图片转base64
     */
        // 图片转base64
        // img=img标签实例对象
        imageToBase64(img) {
            // 创建一个canvas标签实例对象
            let canvas = document.createElement("canvas");

            // 设置宽高以图片实际宽高为准
            canvas.width = img.width;
            canvas.height = img.height;

            // 创建一个canvas2d对象
            let ctx = canvas.getContext("2d");
            // 生成图画对象
            ctx.drawImage(img, 0, 0, img.width, img.height);
            // 提取图片url中最后一个.后面的字符串，这里想获取的是url中文件格式
            // 最后调用toLowerCase转成小写
            let ext = img.src.substring(img.src.lastIndexOf()).toLowerCase();
            // 调用canvas自带的转base64字符串

            let dataURL = canvas.toDataURL("image/jpeg" + ext);
            console.log(canvas.toBlob(function (blob) {
                console.log(blob, new File([blob], '123', { type: 'image/png' }))
            }, 'image/png', 0.95))
            return dataURL;
        },
        // base64转文件对象
        // urlData=base64对象，fileName=生成文件需要name
        base64ToFile(urlData, fileName) {
            // 调用字符串方法分割成数组
            let arr = urlData.split(",");
            // 获得文件base64中，文件格式字符串
            let mime = arr[0].match(/:(.*?);/)[1];
            let index = fileName.lastIndexOf("."); // 从字符串尾部开始查找第一个.出现的位置
            let ext = fileName.substr(index + 1); // 抽取字符串, 只传一个参数就是从这个数字的位置开始取到最后一个字符
            mime = mime.split("/")[0] + '/' + ext;
            // 解码base64,为JavaScript字符串，备注atob方法不适用nodejs
            let bytes = atob(arr[1]);
            function b64DecodeUnicode(str) {
                // Going backwards: from bytestream, to percent-encoding, to original string.
                return decodeURIComponent(atob(str).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
            }
            // 获得解码后的长度
            let n = bytes.length;

            // 创建一个长度为解码后的base64长度，value默认值为0
            let ia = new Uint8Array(n);

            while (n--) {
                // 以解码后base64长度声明的ia数组变量，给它的每个键
                // 赋值转码的base64字符串ASCII码
                ia[n] = bytes.charCodeAt(n);
            }
            // 返回创建文件对象，构成文件主体的参数1需要的是一个表示base64 ASCII码的数组
            return new File([ia], fileName, { type: mime });
        },
        // 手动把图片标签转Base64
        // url=图片地址，cb=回调函数
        // handleImgToBase64(url, cb) {
        //     // console.log(1);
        //     this.axios.get(url, {
        //         auth: {
        //             username: 'janedoe',
        //             password: 's00pers3cret'
        //         },
        //         responseType: 'blob'
        //     }).then(res => {
        //         console.log(res)
        //     });
        //     if (this.judgeUrlNameSuffix(url)) {
        //         setTimeout(() => {
        //             cb({
        //                 name: url,
        //             });
        //         }, 0);
        //         return;
        //     }

        //     // 实例化一个图片对象
        //     let image = new Image();
        //     image.crossOrigin = "";
        //     // 给img赋值url
        //     image.src = url;
        //     let that = this;
        //     image.onload = function () {
        //         // 调用图片转base64方法，获得base64
        //         let base64 = that.imageToBase64(image);
        //         console.log(this.response)
        //         // 调用base64转文件对象方法，获得生成的图片类型文件对象
        //         // 这里文件名实参使用url
        //         let file = that.base64ToFile(base64, url);
        //         // 判断有没有使用回调函数cb, 同时类型检查是不是函数
        //         if (cb && typeof cb === "function") {
        //             // 调用回调函数，传入一个文件对象
        //             cb(file);
        //         }
        //         return file;
        //     };
        // },
        /**
         * 文件地址转文件对象方法
         * @param {*} url 文件地址
         * @param {*} cb 转换结束的时候调用的回调函数
         */
        urlTurnFile(url, cb) {
            // 使用宏线程异步等待this.lists循环生成结束拿到length属性值再开始执行
            setTimeout(() => {
                
                // 转换前要先判断类型是否符合转换的前提
                
                let obj = this.judgeUrlNameSuffix(url);
                // console.log(obj)
                // 判断计数长度是否为0，说明还没赋值，需要赋值
                if (this.count == 0) {
                    this.count = this.lists.length;
                }
                // 判断lists长度是否满足计数的条件，满足条件count每次减1
                if(this.lists.length){
                    this.count -= 1;
                }
                if (obj.done) {
                    // console.log(obj,url)
                    // this.axios.get(url + '?s=' + Math.random().toString(), { responseType: 'blob' }).then(res => {
                    //     // console.log(res)
                    //     let file = new File([res], url, { type: res.type });
                    //     // console.log(file)
                    //     // console.log(res,file);
                    //     cb(file);

                    //     // 计数是否结束，结束就可以显示图片
                    //     if (this.count == 0) {
                    //         this.tt = true;
                    //     }
                    // }).catch(res => {
                    //     console.log(res)
                    // });
                    setTimeout(() => {
                        cb({
                            name: url,
                            type: this.accept[0].range.includes("image")?
                                "image/png":
                                "video/mp4"
                        });
                        // 计数是否结束，结束就可以显示图片
                        if (this.count == 0) {
                            this.tt = true;
                        }
                    }, 0);
                } else {
                    setTimeout(() => {
                        cb({
                            name: url,
                            type: "url地址文件类型验证失败"
                        });
                    }, 0);
                    return;
                }
            }, 4);
        },
        judgeUrlNameSuffix(url) {
            let filename = url;
            let index1 = filename.lastIndexOf(".");
            let index2 = filename.length;
            let postf = filename.substring(index1, index2);

            // let accept = this.accept; // 获得允许文件类型范围
            // accept = accept.split(" ").join(""); // 以防万一清理空格
            // accept = accept.split(",");
            let accept = this.acceptSuffixs;
            return {
                done: accept.find((n) => {
                    return ('.' + n) === postf.toLowerCase()?true:false
                }),
                type: postf
            }
        },
    }
}