import Vue from 'vue';
import VueRouter from 'vue-router';
import routers from "./routers";

Vue.use(VueRouter)

const routes = [
	{
		path: '/test/form',
		name: 'Form',
		component: () => import('@/views/test/form')
	},
	...routers
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router
