const staticVal = {
	Code: {
		Success: 200,
		Fail: 0,
		TableError: -1,
		NotFound: 404,
		Error: 999,
		Invalid: 401,
		Expired: 403
	}
}

export default staticVal
