import axios from 'axios';
import Vue from 'vue';
import routers from "../router/index";
import store from '../store/index';

let show_error_message = true

function create(url) {
	const service = axios.create({
		baseURL: url,
		timeout: 100000,
		headers: {
			'Content-Type': 'application/json',
			'X-Requested-With': 'XMLHttpRequest',
			'Access-Control-Allow-Origin': '*',
		}
	});

	// 添加请求拦截器
	service.interceptors.request.use(
		config => {
			config.headers['Authorization'] = sessionStorage.getItem('token') || '';
			return config
		},
		error => {
			return Promise.reject(error);
		}
	);

	// 添加响应拦截器
	service.interceptors.response.use(
		response => {
			const res = response.data;
			if (Vue.prototype.staticVal.Code.Invalid === res.code) {
				if (show_error_message) {
					show_error_message = false
					// 未提供token或者token过期，需重新登录
					sessionStorage.removeItem("token");
					sessionStorage.removeItem('userid');
					// 菜单权限
					sessionStorage.removeItem('permissionKey');
					// 路由权限
					sessionStorage.removeItem('permissionPathsKey');
					// 操作权限
					sessionStorage.removeItem('operatePermissionKey');
					store.state.token = '';
					Vue.prototype.snackbar.error(res.msg);
					// 跳转到登录页
					routers.push('/login');
					setTimeout(() => {
						show_error_message = true
					}, 3000)
				}
			} else if (Vue.prototype.staticVal.Code.Success !== res.code) {
				if (!(res instanceof Blob)) {
					Vue.prototype.snackbar.error(res.msg);
				}
			}
			return res;
		},
		error => {
			if (error instanceof Error) {
				Vue.prototype.snackbar.error(error);
			} else {
				Vue.prototype.snackbar.error(error.response.data.msg);
			}
			return Promise.reject(error)
		}
	);
	return service;
}

export default async function () {
	let url = "";
	if (process.env.NODE_ENV === "development") {
		url = "https://gztestapi.wujiangvillage.com/";
	} else {
		await axios.get('/serverconfig.json').then(res => {
			url = res.data.apiurl;
		})
	}
	const service = create(url);
	return Promise.resolve(service);
}
