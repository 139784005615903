
export default {
    props: {
        label: {
            type: String,
            default: ""
        },
        // 默认值
        value: {
            type: String | Boolean | Number,
            default: ""
        },
        // 是否启用默认清除输入按钮
        clearable: {
            type: Boolean,
            default: true
        },
        // 输入框菜单列表数组
        items: {
            type: Array,
            default: ()=> ([])
        },
        // 描述
        itemText: {
            type: String,
            default: "desc"
        },
        // code，选择后取哪个对象属性做实际返回值
        itemValue: {
            type: String,
            default: "code"
        },
        // 高度压低
        dense: {
            type: Boolean,
            default: false
        },
        // 验证规则数组
        rules: {
            type: Array,
            default: ()=> []
        },
        // 启用必填项状态
        required: {
            type: Boolean,
            default: false
        },
        // 将轮廓样式应用于输入
        outlined: {
            type: Boolean,
            default: false
        },
        // 启动自动聚焦
        autofocus: {
            type: Boolean,
            default: false
        },
        // 设置输入的占位符文本
        placeholder: {
            type: undefined | String,
            default: undefined
        },
        // 主题色
        color: {
            type: String | undefined,
            default: "primary"
        },
        itemColor: {
            type: String,
            default: "primary"
        },
        // 隐藏提示和错误信息
        hideDetails: {
            type: Boolean | String,
            default: false
        },
        // 禁用编辑功能
        disabled: {
            type: Boolean,
            default: false
        },
        // 没有提供items属性值时的显示文本
        noDataText: {
            type: String,
            default: "没有提供items数组"
        },
        // 多选模式
        multiple: {
            type: Boolean,
            default: false
        },
        // 添加上述没有定义的额外接口属性
        additional: {
            type: Object,
            default: ()=> ({})
        },
        // 改变一个已选择项为小纸片（chips）的显示方式
        chips: {
            type: Boolean,
            default: false
        },
        // 数据返回类型，应用在多选模式下数据返回类型
        arrayType: {
            type: Boolean,
            default: true
        },
        // 提示文本
        hint: {
            type: String | undefined,
            default: undefined
        },
        // 强制提示
        persistentHint: {
            type: Boolean,
            default: false
        },
        // 是否设置为只读
        readonly: {
            type: Boolean,
            default: false
        },
        // 声明指定一个数组里面对象某个属性
        // 作为查询选择符合的数据项。
        someToValue: {
            type: String,
            // 赋值举例：指定某个属性名，然后value在下拉框匹配数据的条件就变成，
            // 在对象中查找这个属性，然后用他的属性值与传的value值进行相等运算
            // 然后返回符合条件的当前对象的code属性值，通过修改itemValue接口值
            // 可以动态定义想要返回的当前对象哪个属性作为下拉框选择值
            default: ""
        }
    }
}
