export default {
    primary: '#639480',
    appBarBackground: '#ffffff', // 系统头部背景颜色
    drawerBackground: '#ffffff', // 系统左侧背景颜色
    drawerFont: '#666666', // 系统左侧菜单字体颜色
    drawerActiveFont: '#ffffff', // 系统左侧菜单激活字体颜色
    sysTabTitle: '#333333', // 系统标题颜色
    highlightBlue: '#5B83FD', // 高亮蓝
    lightBlue: '#8AA7FF', // 浅色蓝
    backdropGrey: '#F6F6FA', // 背景灰
    shadeGrey: '#333333', // 阴影灰,透明度15%
    letterBlack: '#333333', // 文字黑
    letterGrey: '#999999', // 文字灰
    redness: '#FF4949', // 红
    yellow: '#F08E05', // 黄
    headlineBlack: '#040000', // 项目名称色
    masklayer: 'rgba(51, 51, 51, 0.7)', // 遮罩层背景色
    fillGrey: '#CCCCCC', // 背景填充灰
    letterGreen: '#11B211'
}
