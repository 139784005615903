<template>
	<div class="pagination">
		<div style="display: inline-block; font-size: 14px; color: #666666">共<span>{{ total }}</span>
			条记录，每页显示
			<v-autocomplete
					:items="pageSizeArray" dense
					v-model='itemsPerPage'
					@change="input(currentPage, itemsPerPage)"
					style="display: inline-block; width: 40px; font-size: 14px"
					hide-details>
			</v-autocomplete>
			条<!-- ，当前显示
			<template v-if="total > 0">{{ ((currentPage - 1) * itemsPerPage) + 1 }}</template>
			<template v-else>0</template>
			到
			<template v-if="total > 0">
				{{ currentPage * itemsPerPage > total ? total : currentPage * itemsPerPage }}
			</template>
			<template v-else>0</template>
			条-->
		</div>
		<v-pagination
				:total-visible="7" v-model="currentPage" :length="totalPage" @input="input"
				style="display: inline-block"></v-pagination>
	</div>
</template>

<script>
export default {
	name: "pagination",
	props: {
		pageSizeArray: {
			type: Array,
			default: () => []
		},
		pageObject: {
			// 当前页数
			currentPage: 0,
			// 总记录数
			total: 0,
			// 总页数
			totalPage: 0,
			// 每页显示的条数
			itemsPerPage: 10
		}
	},
	data() {
		return {
			// 当前页数
			currentPage: 0,
			// 总记录数
			total: 0,
			// 总页数
			totalPage: 0,
			// 每页显示的条数
			itemsPerPage: 10
		}
	},
	methods: {
		input(v, itemsPerPage) {
			if (!itemsPerPage) {
				itemsPerPage = this.itemsPerPage;
			} else {
				localStorage.setItem("pageSizeKey", itemsPerPage);
			}
			// 直接传递给上上组件
			this.$parent.$emit('changePage', v, itemsPerPage);
		}
	},
	watch: {
		pageObject: {
			handler(obj) {
				if (obj.currentPage > obj.totalPage && obj.totalPage !== 0) {
					this.input(obj.totalPage);
				} else {
					Object.assign(this, obj);
				}
			},
			immediate: true
		},
	},
}
</script>

<style scoped lang="scss">
::v-deep.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
	box-shadow: unset;
}

::v-deep {
	.mdi-menu-down::before, .v-input__icon {
		display: none;
	}

	.v-select:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__slot > input {
		text-align: center;
	}

	.v-autocomplete.v-select.v-input--is-focused input {
		min-width: 40px;
	}

	.v-pagination__navigation, .v-pagination__item {
		box-shadow: 0 1px 2px 0 rgba(51, 51, 51, 0.15);
		font-size: 14px;
		width: auto;
		height: 32px;
		min-width: 32px;
	}
}

.pagination {
	background: #ffffff;
	border-top: 1px solid #E7E7E7;
	border-radius: 0 0 4px 4px;
	text-align: right;
	height: 48px;
}
</style>
