import Vue from 'vue';

function Snackbar() {
	this.messageObject = {
		'success': ['#4caf50', 'mdi-check-circle'],
		'error': ['#EF5350', 'mdi-close-circle'],
		'info': ['#42A5F5', 'mdi-alert-circle'],
		'warning': ['#f6ad55', 'mdi-alert-circle']
	};
}

Snackbar.prototype.commonMethod = function (message, type) {
	let messageObjectElement = this.messageObject[type];
	Vue.prototype.$snackbar({
		content: message,
		color: messageObjectElement[0],
		icon: messageObjectElement[1],
		outlined: true,
		showClose: true,
		timeout: 3000
	});
}

// 成功的消息
Snackbar.prototype.success = function (message) {
	this.commonMethod(message, 'success');
}

// 异常的消息
Snackbar.prototype.error = function (message) {
	this.commonMethod(message, 'error');
}

// 警告的消息
Snackbar.prototype.warning = function (message) {
	this.commonMethod(message, 'warning');
}

// 提示的消息
Snackbar.prototype.info = function (message) {
	this.commonMethod(message, 'info');
}

export default new Snackbar();
