import confirmBox from './confirmBox.vue';
import Vue from 'vue';
import Vuetify from 'vuetify';

function Confirm() {
}

Confirm.prototype.showConfirm = function (content, width, prompt) {
	return new Promise((resolve, reject) => {
		let ConfirmConstructor = Vue.extend(confirmBox);
		let instance = new ConfirmConstructor({
			data: {
				dialog: true,
				width: width ? width : 424,
				content: content,
				resolve: resolve,
				reject: reject,
				prompt: prompt // 不定义该值则默认为删除提示
			}
		});
		instance.$vuetify = new Vuetify().framework;
		instance.$mount();
		document.body.appendChild(instance.$el);
		instance.confirmVisible = true;
	});
}

export default new Confirm();
