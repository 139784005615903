<template>
	<div>
		<!-- 标题 -->
		<div
				class="form-row-title"
				:style="{ marginBottom: title ? '16px' : '' }"
				v-if="title"
		>
			{{ title }} <span v-if="required" style="color: #f56473">*</span>
		</div>
		<div class="d-flex align-start" style="margin-bottom: 16px" v-if="!imgnot">
			<template v-if="icon === 'icon-tupian'">
				<div
						v-for="(list, index) in lists"
						:key="index"
						class="mr-6"
						style="
            width: 132px;
            height: 132px;
            background: var(--v-fillGrey-base);
            border-radius: 4px;
            position: relative;
          "
				>
					<div
							style="
              position: absolute;
              color: var(--v-letterBlack-base);
              font-size: 14px;
              width: 72px;
            "
							class="czjz"

					>
						{{ list.url ? "" : reminder }}
					</div>
					<!-- @click="checkBigPicture(list.url)" -->
					<v-img
							max-height="132"
							height="132px"
							max-width="132"
							:src="list.url + '?x-oss-process=image/resize,m_lfit,h_750,w_300'"
							:lazy-src="
              list.url + '?x-oss-process=image/resize,m_lfit,h_750,w_300'
            "
							v-show="tt"
							transition="fade-transition"
					>
					</v-img>
				</div>
			</template>
		</div>
		<div v-for="(item, index) in lists" :key="index">
			<!-- calc()两个按钮=80px,三个按钮=126px,一个按钮=32+16 -->
			<!--         :rules="required ? relus1 : []"show-size -->
			<v-file-input
					validate-on-blur
					:rules="required ? relus1 : []"
					:accept="acceptRange.toString()"
					:label="label"
					:prepend-icon="icon ? `iconfont ${icon}`: ''"
					@change="modification($event, index)"
					v-model="item.file"
					outlined
					dense
					:hide-details="hideDetails"
					:required="required"
					:hint="hint"
					:persistent-hint="persistentHint"
					:loading="loadings[index]"
					:style="{ marginBottom: hint ? '16px' : '0px', width: uniline ? '100%': 'calc(100% - 126px)'}"
					:disabled="disabled"
					:clearable="clearablet"
					:multiple="materialSource"
					:error-messages="errorMessages[index]"
					ref="wfile"
			>
				<template v-slot:append-outer>
					<div style="position: absolute; width: 128px">
						<v-btn
								v-if="item.move"
								class="mr-4"
								width="32px"
								height="32px"
								color="primary"
								min-width="32px"
								depressed
								@click="moveUp(index)"
						>
							<v-icon dark size="16" color="white">
								iconfont icon-shengxu
							</v-icon>
						</v-btn>
						<v-btn
								v-if="item.add"
								class="mr-4"
								width="32px"
								height="32px"
								color="primary"
								min-width="32px"
								depressed
								@click="increased"
						>
							<v-icon dark size="16"> iconfont icon-zengjia</v-icon>
						</v-btn>
						<v-btn
								v-if="item.del"
								width="32px"
								height="32px"
								color="redness white--text"
								min-width="32px"
								depressed
								@click="remove(item.url, index)"
						>
							<v-icon size="16" color="white"> iconfont icon-shanchu-fill</v-icon>
						</v-btn>
					</div>
				</template>
			</v-file-input>
		</div>
		<v-dialog v-model="dialog" persistent :width="width + 40">
			<v-card style="padding: 20px; padding-top: 30px">
				<div>
					<v-icon
							@click="dialog = false"
							style="position: absolute; right: 14px; margin-top: -25px"
					>mdi-close
					</v-icon
					>
				</div>
				<div style="width: 100%; overflow: auto; max-height: 85vh">
					<img :src="imgurl" alt="" max-width="100%"/>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import props from "./w-file-input-props.js";
import methods from "./w-file-input-methods.js";
import api from "@/api/file/file.js";

export default {
	mixins: [props, methods],
	data() {
		return {
			imgaes: [],
			lists: [],
			dialog: false,
			imgurl: "",
			width: 0,
			relus1: [
				(file) => {
					// 通过对象原型继承判断对象是否是一个文件对象，不是同时又是一个对象则不需要效验
					if (!(file instanceof File) && file instanceof Object) {
						if (JSON.stringify(file) === "{}") {
							return "当前没有选择文件";
						} else if (file.type === "url地址文件类型验证失败") {
							return file.type;
						}
						return true;
					} else if (file == null) {

						return "当前没有选择的文件";
					}
					if (!this.acceptRange.includes(file.type)) {
						return "上传文件格式不符合规定的类型";
					}
					if (file.size > this.size * 1024 * 1024) {
						this.$emit('upload_limit')
						return "上传文件已超出大小范围";
					}
					return true;
				},
			],
			relus2: [
				(file) => {
					return "上传文件已超出大小范围";
				}
			],
			loadings: [],
			memorys: null,
		};
	},
	computed: {
		acceptRange() {
			return this.accept.map((n) => {
				return n.range;
			});
		},
		acceptSuffixs() {
			return this.accept.map((n) => n.suffixs).flat();
		},
		clearablet() {
			return this.lists.length === 1
					? !!this.lists[0].url
					: false;
		},
	},
	// created() {
	//   this.relus1[0] = this.rulesFn;
	// },
	methods: {
		// 查看大图
		checkBigPicture(url) {
			// console.log(url);
			if (url) {
				let img = new Image();
				img.src = url;
				img.onload = () => {
					this.width = img.width;
					this.imgurl = url;
					this.dialog = true;
				};
			}
		},
		// 事件推送
		transmit() {
			this.memorys = this.lists
					.filter((item) => {
						return !!item.url;
					})
					.map((item) => {
						return item.url;
					})
					.toString();
			// console.log(this.memorys);
			this.$emit("change", this.memorys);
		},
		async modification(v, index) {

			// 判断是否完成了效验，没有的话就在这里结束
			let verify = this.relus1[0](this.lists[index].file);
			if (typeof verify === "string") {
				if (verify === "当前没有选择的文件") {
					this.lists[index].url = "";
					this.transmit();
				}
				this.tt = false;
				// return false;
			}

			// 判断是不是没有启用必填项
			// 没有启用必填项，验证规则就不会使用，所以要手动验证
			if (!this.required) {
				let rules = this.relus1[0];
				if (Array.isArray(v)) {
					let res = true
					v.forEach(vv => {
						let result = rules(vv);
						if (typeof result === "string") {
							this.$set(this.errorMessages, index, result);
							this.tt = false;
							res = false
						}
					})
					if (!res) {
						return
					}
				} else {
					let result = rules(v);
					// console.log(result);
					if (typeof result === "string") {
						this.$set(this.errorMessages, index, result);
						this.tt = false;
						return;
					}
				}
			}
			this.tt = true;
			// if (this.rulesFn(v) === true) {
			//   // 取反判断是上传文件情况
			//   if (v !== null && v instanceof File) {
			//     this.lists[index].file = v;
			//     await this.upload(v, index);
			//   } else {
			//     // 删除文件情况
			//     await this.expurgate(this.lists[index].url, "input");
			//     this.lists[index].url = "";
			//   }
			//   this.transmit();
			// }
			// 取反判断是上传文件情况
			// v !== null && v instanceof File && this.accept.includes(v.type)
			if (v !== null && this.materialSource) {
				if (v instanceof File) {
					this.lists[index].file = v;
					await this.upload(v, index, true);
				} else if (v instanceof Array) {
					v.forEach((vv, vi) => {
						this.lists[index].file = vv;
						this.upload(vv, index, vi === v.length - 1);
					})
				}
			} else if (v !== null && v instanceof File) {
				this.lists[index].file = v;
				await this.upload(v, index);
			} else {
				// 删除文件情况
				// await this.expurgate(this.lists[index].url, "input");
				this.lists[index].url = "";
			}
			// console.log(123);
			this.transmit();
		},
		// 上传文件
		upload(file, index, last) {
			this.$emit('startUpload')
			this.$set(this.errorMessages, index, "");
			this.$set(this.loadings, index, true);
			let data = new FormData();
			data.append("file", file);
			data.append("fileType", this.fileType);
			this.lists[index].file = null;
			return this.axios
					.post(api.upload_file, data, {
						headers: {"Content-Type": "multipart/form-data"},
					})
					.then((res) => {
						if (res.code === this.staticVal.Code.Success) {
							this.$set(this.loadings, index, "success");
							this.lists[index].url = res.data.ossFileUrl;
							setTimeout(() => {
								this.$set(this.loadings, index, false);
							}, 2000);
							this.$emit('success', last)
						} else {
							this.$set(this.loadings, index, "error");
							this.errorMessages[index] = `${file.name}上传失败${res.msg}`;
							this.lists[index].file = {};
							setTimeout(() => {
								this.$set(this.loadings, index, false);
							}, 2000);
						}
					})
					.catch(() => {
						this.loadings[index] = false;
					});
		},
		// 删除文件
		expurgate(url, manner) {
			return this.axios
					.post(api.delete_file, {
						ossFileUrl: url,
					})
					.then(() => {
						// 通过第二个参数来取反判断调用删除的不是输入框上自带的x删除icon
						if (!manner) {
							this.transmit();
						}
					});
		},
		// 数组位置互换方法
		swapArray(arr, index1, index2) {
			// 数组互换逻辑：删除顺序在后的元素，并把顺序在前的替换插入在他原来的位置
			// splice会返回被删除的当前元素，然后赋值在顺序在前的位置，就替换成功了
			let as = arr.splice(index2, 1, arr[index1]);
			arr[index1] = as[0];
			return arr;
		},
		// 上移方法
		moveUp(index) {
			// console.log(this.lists,index)
			// 执行交换
			this.swapArray(this.lists, index - 1, index);
			// 判断是不是跟第一个互换
			if (index - 1 === 0) {
				this.lists[0].move = false;
				this.lists[0].add = false;
				this.lists[1].move = true;
				if (this.lists.length === 2) {
					this.lists[1].add = true;
				}
			} else if (index === this.lists.length - 1) {
				// 判断点击上移的行数，是当前最后一个行数情况处理
				this.lists[index].add = true;
				this.lists[index - 1].add = false;
			}
			// 调用推送给调用者组件更新状态
			this.transmit();
		},
		// 增加方法
		increased() {
			// 判断当前行数是否达到了最大限制数
			if (this.lists.length < this.max) {
				// 处理行数只有1的情况
				if (this.lists.length === 1) {
					this.lists[this.lists.length - 1].add = false;
					this.lists[this.lists.length - 1].del = true;
				} else {
					// 新增行时，行数已2个以上情况处理
					this.lists[this.lists.length - 1].add = false;
					this.lists[this.lists.length - 1].del = true;
					this.lists[this.lists.length - 1].move = true;
				}
				// 新增的行在末尾，所以直接push
				this.lists.push({
					url: "",
					move: true,
					add: true,
					del: true,
				});
			}
		},
		// 删除行方法
		remove(url, index) {
			// 删除数组中当前行
			this.lists.splice(index, 1);
			// 通过url值判断服务器是否存储过图片, 如果有存储过图片则有必要执行服务器请求
			// 删除文件。
			if (url) {
				// this.expurgate(url);
				this.transmit();
			}
			// 判断当前删除行数是第一行的情况
			if (index === 0) {
				this.lists[index].move = false;
				if (this.lists.length === 1) {
					this.lists[index].del = false;
					this.lists[index].add = true;
				}
			} else if (this.lists.length === index && this.lists.length >= 2) {
				// 判断删除是最后一行的情况，并且数组最后长度2以上
				this.lists[index - 1].add = true;
			} else if (this.lists.length === index && index === 1) {
				// 判断删除是最后一行的情况，并且数组长度等于1
				this.lists[0].del = false;
				this.lists[0].add = true;
			}
		},
		//rules规则
		rulesFn(file) {
			// 通过对象原型继承判断对象是否是一个文件对象，不是则不需要效验
			if (!(file instanceof File) && file != null) {
				return true;
			}
			// 判断大小
			// console.log(file.size > this.size * 1024 * 1024, this.size, file.size);
			if (file.size > this.size * 1024 * 1024) {
				return "上传文件已超出大小范围";
			}
			// 判断类型
			if (!this.accept.replace(/\s/g, "").split(",").includes(file.type)) {
				return "上传文件格式不符合规定的类型";
			}
			return true;
		},
		// 生成进度条
		createLoading(num) {
			let loading = [];
			this.loadings = [];
			let errorMessages = [];
			while (num > loading.length) {
				loading.push(false);
				errorMessages.push("");
			}
			this.loadings = loading;
			this.errorMessages = errorMessages;
		},
		// 抽象出一个生存文件数据数组方法
		// list=要添加的数组
		// arr=填充到url的数组
		// one=只生成一个数据项
		createList({list = [], arr = [], one = false, init = false} = {}) {
			// 保存参数2的状态，是否是一个空数组
			let empty = arr.length === 0;

			// 判断是不是只插入一个
			if (one) {
				// 是否需要按钮
				if (!this.notbtn) {
					list.push({
						url: empty ? "" : arr[0],
						move: false,
						add: true,
						del: false,
						file: null,
					});
				} else {
					list.push({
						url: empty ? "" : arr[0],
						move: false,
						add: false,
						del: false,
						file: {},
					});
				}
			}

			// 判断是不是初始化模式
			else if (init) {
				// 初始化模式根据最小生成数min去遍历
				for (let i = 0; i < this.min; i++) {
					this.createList({
						list: list,
						one: true,
					});
				}
				if (this.min >= 2 && this.notbtn === false) {
					list[0] = {
						url: "",
						move: false,
						add: false,
						del: true,
						file: {},
					};
					list[list.length - 1] = {
						url: "",
						move: true,
						add: true,
						del: true,
						file: {},
					};
				}
			}

			return list;
		},
		pp() {
			if (this.lists.length === 0) {
				if (this.min === 1) {
					this.createList({
						list: this.lists,
						one: true,
					});
					if (this.uniline) {
						this.lists[0].add = false;
					}
				} else {
					// 根据默认最小行数渲染生成行数
					// 创建临时数组变量
					let list = [];
					this.createList({
						list: list,
						init: true,
					});

					this.lists = list;
				}
			} else if (this.items === "" && this.uniline === true) {
				// console.log(this.uniline)
				this.lists[0].add = false;
			}
			this.createLoading(this.lists.length);
		},
	},
	mounted() {
		// 判断是空数组处理
		// console.log(this.lists.length,this.items,this.uniline)
		if (this.lists.length === 0) {
			if (this.min === 1) {
				this.createList({
					list: this.lists,
					one: true,
				});
				if (this.uniline) {
					this.lists[0].add = false;
				}
			} else {
				// 根据默认最小行数渲染生成行数
				// 创建临时数组变量
				let list = [];
				this.createList({
					list: list,
					init: true,
				});
				this.lists = list;
			}
		} else if (this.items === "" && this.uniline === true) {
			// console.log(this.uniline)
			this.lists[0].add = false;
		}
		this.createLoading(this.lists.length);

		// 使用创建的文件对象给文件上传组件读取成功
		// this.urlTurnFile("https://datascenic.oss-cn-hangzhou.aliyuncs.com/001/roomType/2021/10/11/3520d51e49f048a3b9ef67495c6703c1.mp4",(file)=> {
		//   this.lists[0].file = file;
		//   let obj = this.lists[0];
		//   obj.file = file;
		//   this.$set(this.lists,0,obj);
		// });
		// console.log(this.loadings);
		// console.log(this.size)
	},
	watch: {
		// lists: {
		//   handler(current) {
		//     console.log(current)
		//   }
		// },
		items: {
			handler(val) {
				if (val) {
					if (this.memorys === val) {
						return;
					}
					// 把字符串分割成数组
					let list = val.split(",");
					let lists;
					// 数量为1
					if (list.length === 1) {
						// 判断如果单行模式没有启用，可以添加按钮
						if (!this.uniline) {
							lists = this.createList({
								list: [],
								arr: [list[0]],
								one: true,
							});
							this.urlTurnFile(list[0], (file) => {
								// console.log(file, this.lists);
								this.lists[0].file = file;
							});
							// this.lists[0].file = {
							//   name: list[0]
							// }
						} else {
							lists = [
								{
									url: list[0],
									move: false,
									add: false,
									del: false,
									file: {},
								},
							];
							this.urlTurnFile(list[0], (file) => {
								// console.log(file, this.lists[0].file);
								this.lists[0].file = file;
							});
						}
					}
					// 数量不为1，此处遍历生成s，同时判断是否启用单行模式
					else {
						if (this.notbtn) {
							lists = list.map((item) => {
								let obj = {
									url: item,
									move: false,
									add: false,
									del: false,
									file: {},
								};
								this.urlTurnFile(item, (file) => {
									obj.file = file;
								});
								return obj;
							});
						} else {
							lists = list.map((item) => {
								let obj = {
									url: item,
									move: true,
									add: false,
									del: true,
									file: {},
								};
								this.urlTurnFile(item, (file) => {
									obj.file = file;
								});
								return obj;
							});
							lists[0].move = false;
							lists[lists.length - 1].add = true;
						}
					}
					this.lists = lists;
					this.createLoading(this.lists.length);
				} else {
					this.lists = [];
					this.pp()
				}
				// console.log(this.lists)
			},
			immediate: true,
			deep: true,
		},
		rules: {
			handler(val) {
				this.relus1 = val;
			},
		},
	},
};
</script>

<style lang="scss">
.lessen-scss {
	.v-input__prepend-outer {
		width: 14px;
		height: 14px;

		button::before {
			width: 14px !important;
			height: 14px !important;
		}
	}
}
</style>
