const fileType = ['image', 'video', 'audio'];
export default {
    props: {
        // 文件格式类型
        // 比如限定使用哪些文件
        accept: {
            type: Array,
            // 默认为图片格式类型，格式为表单编辑中出现重复率最多的值
            // default: "image/png,image/jpeg",
            default: () => [
                {range: 'image/png', suffixs: ['png']},
                {range: 'image/jpeg', suffixs: ['jpg']}
            ],
            /**
             * 自定义验证函数
             * @param {Array} v 参数必定是数组，因为类型错误触发在验证函数调用前
             * @returns {Boolean}
             */
            // validator(v) {
            //     if (condition) {

            //     }
            // }
            // validator(v) {
            //     // console.log(v.split('/'), v.split(","));
            //     let vlas = v.replace(/ /g, "").split('/').join().split(',');
            //     // console.log(vlas.join())
            //     let len = vlas.length;
            //     for (let i = 0; i < len; i+=2) {
            //         // console.log(vlas[i],vlas)
            //         if (fileType.indexOf(vlas[i]) === -1) {
            //             return false;
            //         }
            //     }
            //     return true;
            // }
        },
        // 文件所属目录类型
        fileType: {
            type: String,
            default: 'roomType'
        },
        // 用来生成列表的对象，接收一个,分割字符串
        items: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        // 最小上传数
        // 根据最小数默认渲染多少条上传输入框
        min: {
            type: Number,
            default: 1
        },
        // 最大上传数(常用)
        // 限制最大可生成的上传输入框
        max: {
            type: Number,
            default: 6
        },
        // 启用单行模式,
        // 在单行模式下，去除了三个按钮功能，并且最小生成数固定1
        uniline: {
            type: Boolean,
            default: false
        },
        // 启用无按钮模式
        notbtn: {
            type: Boolean,
            default: false
        },
        // 出错提示规则
        rules: {
            type: Array,
            default: () => []
        },
        // 启用必填项
        required: {
            type: Boolean,
            default: false
        },
        // 提示文本
        hint: {
            type: String,
            default: ""
        },
        // 启用强制提示
        persistentHint: {
            type: Boolean,
            default: true
        },
        // 覆盖组件前置图标
        icon: {
            type: String,
            // icon-tupian=图片类型,icon-shipin=视频类型
            default: "icon-tupian"
        },
        // 文件大小限制，以M为单位
        size: {
            type: Number,
            default: 2
        },
        // 预览图片的div文本
        reminder: {
            type: String,
            default: '上传预览图'
        },
        // 设置为只读
        disabled: {
            type: Boolean,
            default: false
        },
        imgnot: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        // 素材来源
        materialSource: {
            type: Boolean,
            default: false
        }
    },
}
