<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import commonSetting from "@/api/systemManage/common-setting.js";
// import axios from "axios";
// import Vue from 'vue'
export default {
  name: "App",

  data: () => ({
    //
  }),
  // methods: {
  //   // 修改项目信息配置
  //   configuration(obj) {
  //     document.getElementsByTagName("title")[0].innerText = obj.title;
  //   },
  // },
  // computed: {
  //   po() {
  //     return this.axios;
  //   },
  // },
  // created() {
  //   Vue.prototype.loadconf = ()=> {
  //     this.axios.post(commonSetting.load, {}).then((res) => {
  //       if (res.code === this.staticVal.Code.Success) {
  //         this.configuration(res.data);
  //       }
  //     });
  //   };
  // },
};
</script>
