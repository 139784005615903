import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		token: sessionStorage.getItem('token') || '',
		appBarTitle: '',
		secondResource: '',
		// 设置根据接口获取数据生成头部导航列表
		header: null,
		// 左侧激活的菜单列表
		activeMenu: '',
		oneSource: '',
		indexDashboard: {},
		rule_object_type: ''
	},
	mutations: {

		/**
		 * 存储token
		 * @param state
		 * @param token
		 */
		setToken: function (state, token) {
			state.token = token;
			sessionStorage.setItem('token', token);
		},
		setUserId: function (state, userid) {
			sessionStorage.setItem('userid', userid);
		},
		setAppBarTitle: function (state, title) {
			state.appBarTitle = title;
		},
		setSecondResource: function (state, v) {
			state.secondResource = v;
		},
		setOneSource: function (state, v) {
			state.oneSource = v
		},
		setDashboard: function (state, v) {
			state.indexDashboard = v
		}
	}
});
