<template>
	<div class="d-wrapper">
		<v-tabs v-if="!bouncedMode" centered @change="tabChange">
			<v-tab>图片</v-tab>
			<v-tab>视频</v-tab>
			<v-tab>音频</v-tab>
		</v-tabs>
		<div class="d-container" :style="{height: bouncedMode ? contentHeight + 'px': 'calc(100vh - 56px - 24px - 48px)'}">
			<div style="height: 100%;" :style="{width: leftWidth + 'px'}">
				<div
						class="d-left-container"
						style="overflow: auto"
						:style="{height: showInfo && !bouncedMode ? 'calc(100% - 302px)': '100%'}">
					<div class="d-flex dir-padding" :class="activeDirCode === rootPath ? 'active-dir': ''">
						<div style="cursor: pointer; white-space: nowrap" @click="activeDirCode = rootPath">默认素材库</div>
						<div style="cursor: pointer; margin-left: 140px" @click="openDialog" v-if="!bouncedMode">
							<v-icon class="iconfont" size="24" :color="rootPath === activeDirCode ? '#ffffff' : '#333333'">iconfont
								icon-zengjia
							</v-icon>
						</div>
					</div>
					<div v-for="(item, index) in dirList" :key="index">
						<div
								class="d-flex justify-space-between dir-padding"
								:class="activeDirCode === item.code ? 'active-dir': ''">
							<div class="one-line" @click="getDirFiles(item)" style="cursor: pointer">
								{{ item.desc }}
							</div>
							<div class="d-flex">
								<div class="operate-btn mr-2" @click="editDir(item)" v-if="!bouncedMode">
									<v-icon size="24" class="iconfont" :color="activeDirCode === item.code ? '#ffffff' : '#333333'">
										iconfont icon-bianji2-fill
									</v-icon>
								</div>
								<div class="operate-btn mr-2" @click="deleteDir(item)" v-if="!bouncedMode">
									<v-icon size="24" class="iconfont" :color="activeDirCode === item.code ? '#ffffff' : '#333333'">
										iconfont icon-shanchu-fill
									</v-icon>
								</div>
								<div v-if="item.children && item.children.length">
									<v-icon
											size="24" @click="spreadId = spreadId ? null: item.code"
											:color="activeDirCode === item.code ? '#ffffff' : '#333333'">
										{{ spreadId === item.code ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
									</v-icon>
								</div>
							</div>
						</div>
						<div
								v-show="spreadId === item.code"
								v-for="sub in item.children"
								:class="activeDirCode === sub.code ? 'active-dir': ''"
								:key="sub.code"
								class="dir-padding one-line">
							<div style="margin-left: 24px; display: flex; justify-content: space-between">
								<div @click="getDirFiles(sub)" style="cursor: pointer">{{ sub.desc }}</div>
								<div class="d-flex" v-if="!bouncedMode">
									<div class="operate-btn mr-2" @click="editDir(sub)">
										<v-icon size="24" class="iconfont" :color="activeDirCode === sub.code ? '#ffffff' : '#333333'">
											iconfont icon-bianji2-fill
										</v-icon>
									</div>
									<div class="operate-btn mr-2" @click="deleteDir(sub)">
										<v-icon size="24" class="iconfont" :color="activeDirCode === sub.code ? '#ffffff' : '#333333'">
											iconfont icon-shanchu-fill
										</v-icon>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="d-left-container d-left-container-info pa-4" v-if="showInfo && !bouncedMode">
					<v-text-field
							label="素材名称"
							outlined
							hide-details
							@blur="updateFileName(item, $event)"
							:value="item.objectName.substring(0, item.objectName.lastIndexOf('.'))"
					></v-text-field>
					<div style="font-weight: bold; margin-top: 24px">基本信息</div>
					<div style="font-size: 14px">
						<div
								class="info-row"
								v-if="videoType.includes(calculateUrlSuffix(item.objectUrl))">
							<div class="info-title">时长</div>
							<div>{{ videoTimeLength }}</div>
						</div>
						<div class="info-row" v-else>
							<div class="info-title">尺寸</div>
							<div>{{ imageWidthHeight }}</div>
						</div>
						<div class="info-row">
							<div class="info-title">文件大小</div>
							<div>{{ item.size | fileSize }}</div>
						</div>
						<div class="info-row">
							<div class="info-title">格式</div>
							<div>{{ calculateUrlSuffix(item.objectUrl) }}</div>
						</div>
						<div class="info-row">
							<div class="info-title">创建日期</div>
							<div>{{ item.updateTime }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="d-right-container" :style="{width: 'calc(100% - ' + (leftWidth + 40) + 'px)'}">
				<div>
					<div class="d-flex justify-space-between">
						<div class="d-flex align-center" style="gap: 20px">
							<v-text-field v-model="searchkey" placeholder="输入素材名称" hide-details dense clearable/>
							<w-autocomplete @change="format = $event" placeholder="素材格式" hide-details dense :items="format_list"/>
							<v-btn color="primary" depressed @click="getOssList">查询</v-btn>
						</div>
						<v-btn style="position: relative;" depressed color="primary">
							<v-icon style="margin-top: 2px" class="iconfont" size="14">iconfont icon-shangchuan</v-icon>
							上传素材
							<WFileinput
									@startUpload="startUpload"
									ref="uploadRef"
									class="d-upload"
									:uniline="true"
									:fileType="activeDirCode"
									@success="uploadSuccess"
									label="本地上传"
									icon=""
									:accept="accept && accept.length > 0 ? accept: accept_list"
									:key="fileKey"
									:material-source="true"
									:hide-details="true"
									@upload_limit="upload_limit"
									:size="file_size"
									:imgnot="true"/>
						</v-btn>
					</div>
					<v-divider class="mt-2"/>
				</div>
				<div style="overflow-y: auto; height: calc(100% - 32px); overflow-x: hidden">
					<div v-if="!bouncedMode" class="d-flex justify-space-between" style="align-items: end">
						<div class="d-flex mt-4">
							<v-checkbox
									label="全选"
									:indeterminate="indeterminate"
									:input-value="inputValue"
									@change="allSelectEvt"
									hide-details
									dense
							></v-checkbox>
							<v-btn
									@click="deleteItem(selectItems)"
									depressed color="redness"
									class="white--text ml-4"
									small
									v-if="!deleteBtnStatus">
								<v-icon class="iconfont mr-1" size="13">iconfont icon-shanchu-fill</v-icon>
								批量删除
							</v-btn>
						</div>
						<div style="font-size: 12px; color: #999999">
							{{ upload_limit_desc[activeTab] }}
						</div>
					</div>
					<div class="d-flex flex-wrap" ref="contentRef">
						<div
								v-for="(item, index) in images"
								:key="index"
								class="image-box mb-9"
								@click="selectChange(item.objectUrl, !selectItems.includes(item.objectUrl))"
								style="width: 120px; height: 120px; position: relative; cursor: pointer"
								:style="{margin: '20px ' + imagePadding + 'px'}"
						>
							<div
									v-if="!acceptSuffix.includes(calculateUrlSuffix(item.objectUrl))"
									style="width: 120px; height: 120px; background: rgba(0, 0, 0, .5); position: absolute; z-index: 8; border-radius: 8px; cursor: not-allowed"
							/>
							<div v-if="item.loading" class="d-image-box">
								<v-progress-linear
										color="primary"
										indeterminate
										rounded
										height="6"
								></v-progress-linear>
							</div>
							<div
									v-else-if="activeTab === 2"
									class="d-image-box">
								<v-icon size="36" class="iconfont box-center">iconfont icon-yinpin-fill</v-icon>
							</div>
							<v-img
									v-else
									contain
									class="d-image-box"
									:src="activeTab === 1 ? item.objectUrl + '?x-oss-process=video/snapshot,t_1000,m_fast':
									(item.objectUrl.endsWith('.ico') || item.objectUrl.endsWith('.svg')) ? item.objectUrl: item.objectUrl + '?x-oss-process=image/resize,m_lfit,h_120,w_120'"
							/>
							<div
									class="text-center mt-2 two-line"
									style="font-size: 12px; width: 120px;"
									:style="{color: selectItems.includes(item.objectUrl) ? 'var(--v-primary-base)': '#333333'}">
                  <span :style="{color: acceptSuffix.includes(calculateUrlSuffix(item.objectUrl)) ? '': '#999999'}">
                    {{ item.objectName }}
                  </span>
							</div>
							<div
									v-if="!item.loading"
									@click="deleteItem([item.objectUrl])"
									class="close-icon"
									style="position: absolute; right: -10px; top: -15px; cursor: pointer; display: none">
								<v-icon size="32" class="iconfont" color="#000000">iconfont icon-guanbi-fill</v-icon>
							</div>
							<div style="position: absolute; right: 0; bottom: 10px">
								<v-checkbox
										v-if="acceptSuffix.includes(calculateUrlSuffix(item.objectUrl))"
										hide-details @click="selectChange(item.objectUrl, selectItems.includes(item.objectUrl))"
										:value="selectItems.includes(item.objectUrl)"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<exhibition-data-box
					:dialog.sync="dialog"
					:width="368"
					bgColor="#ffffff"
					:contentHeight="240"
					title="新建分组"
			>
				<v-tabs centered grow v-model="tabs">
					<v-tab>一级分组</v-tab>
					<v-tab>二级分组</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tabs" class="mt-3">
					<v-tab-item>
						<v-form v-model="valid1" ref="form1">
							<v-text-field
									v-model="form1.dirName"
									label="分组名称"
									hint="建议在8个中文以内"
									persistent-hint
									class="asterisk"
									required
									:rules="[v => !!v || '必填项，建议在8个中文以内']"/>
						</v-form>
					</v-tab-item>
					<v-tab-item>
						<v-form v-model="valid2" ref="form2">
							<w-autocomplete
									label="一级分组"
									class="asterisk"
									required
									:items="selectList"
									:value="form2.group"
									@change="form2.group = $event"
									:rules="[v => !!v || '必填项']"/>
							<v-text-field
									label="分组名称"
									hint="建议在8个中文以内"
									v-model="form2.dirName"
									persistent-hint
									class="asterisk"
									required
									:rules="[v => !!v || '必填项，建议在8个中文以内']"/>
						</v-form>
					</v-tab-item>
				</v-tabs-items>
				<template #footer>
					<div class="text-center">
						<v-btn depressed color="primary" outlined @click="dialog = false">取消</v-btn>
						<v-btn class="ml-8" depressed color="primary" @click="saveGroup">确定</v-btn>
					</div>
				</template>
			</exhibition-data-box>

			<exhibition-data-box
					:dialog.sync="renameDialog"
					:width="368"
					bgColor="#ffffff"
					:contentHeight="140"
					title="重命名目录"
			>
				<div style="font-size: 14px; margin-bottom: 30px">
					此操作可能会影响到应用端素材显示，如分组下的素材正在被使用，请更新应用端的素材
				</div>
				<v-form v-model="valid1" ref="form1">
					<v-text-field
							v-model="renameItem.desc"
							label="分组名称"
							hint="建议在8个中文以内"
							persistent-hint
							class="asterisk"
							required
							:rules="[v => !!v || '必填项，建议在8个中文以内']"/>
				</v-form>
				<template #footer>
					<div class="text-center">
						<v-btn depressed color="primary" outlined @click="renameDialog = false">取消</v-btn>
						<v-btn class="ml-8" depressed color="primary" @click="renameDir">确定</v-btn>
					</div>
				</template>
			</exhibition-data-box>
		</div>
	</div>
</template>

<script>
import exhibitionDataBox from "@/components/exhibition-data-box/exhibition-data-box"
import wAutocomplete from '@/components/w-autocomplete/w-autocomplete.vue';
import WFileinput from "@/components/w-file-input/w-file-input";

export default {
	components: {
		exhibitionDataBox,
		wAutocomplete,
		WFileinput
	},
	props: {
		// 多选
		multiple: {
			type: Boolean,
			default: false
		},
		// 弹框模式
		bouncedMode: {
			type: Boolean,
			default: false
		},
		imageWidth: {
			type: Number,
			default: 140
		},
		leftWidth: {
			type: Number,
			default: 320
		},
		contentHeight: {
			type: Number,
			default: 600
		},
		accept: {
			type: Array,
			default: () => []
		},
		remark: {
			type: Number,
			default: 1
		}
	},
	data() {
		return {
			accept_list: [],
			file_size: 2,
			dialog: false,
			tabs: null,
			valid1: false,
			valid2: false,
			indeterminate: false,
			inputValue: false,
			deleteBtnStatus: true,
			imagePadding: 15,
			selectItems: [],
			showInfo: false,
			item: {},
			images: [],
			dirList: [],
			spreadId: null,
			rootPath: '',
			activeDirCode: '',
			form1: {
				dirName: '',
				group: ''
			},
			form2: {
				dirName: '',
				group: ''
			},
			// 下拉框列表
			selectList: [],
			renameDialog: false,
			renameItem: {},
			imageWidthHeight: '',
			fileKey: 0,
			videoType: ['mp4', 'webm', 'ogg'],
			videoTimeLength: 0,
			acceptSuffix: [],
			tab_map: {
				0: 'material',
				1: 'video',
				2: 'audio'
			},
			activeTab: 0,
			searchkey: '',
			format: '',
			format_list: [],
			upload_limit_desc: {
				0: '注：上传图片大小不能超过2MB，格式仅限于jpg、jpeg、png、svg、ico、webp',
				1: '注：上传视频大小不能超过50MB，格式仅限于MP4、WebM、Ogg',
				2: '注：上传音频大小不能超过20MB，格式仅限于flac、mp3、wav'
			},
			execute_show_message: true
		}
	},
	computed: {
		// 计算url后缀
		calculateUrlSuffix() {
			return function (url) {
				return url && url.substring(url.lastIndexOf('.') + 1).toLowerCase()
			}
		}
	},
	methods: {
		startUpload() {
			this.images = [{objectName: '素材上传中', loading: true}].concat(this.images)
		},
		upload_limit() {
			if (this.execute_show_message) {
				this.execute_show_message = false
				this.snackbar.error('上传文件已超出大小范围');
				setTimeout(() => {
					this.execute_show_message = true
				}, 3000)
			}
		},
		getFormatList() {
			this.axios.post(this.select_data, {
				keyname: 'ossformat',
				params: [{key: 'ossformat', value: this.tab_map[this.activeTab]}]
			}).then(res => {
				this.format_list = res.data[0].values
			})
		},
		getAccept() {
			switch (this.activeTab) {
				case 0:
					this.file_size = 2
					this.accept_list = [
						{range: 'image/png', suffixs: ['png']},
						{range: 'image/webp', suffixs: ['webp']},
						{range: 'image/jpeg', suffixs: ['jpg', 'jpeg']},
						{range: 'image/svg+xml', suffixs: ['svg']},
						{range: 'image/vnd.microsoft.icon', suffixs: ['ico']}
					]
					break;
				case 1:
					this.file_size = 50
					this.accept_list = [
						{range: 'video/mp4', suffixs: ['mp4']},
						{range: 'video/webm', suffixs: ['webm']},
						{range: 'video/ogg', suffixs: ['ogg']}
					]
					break;
				case 2:
					this.file_size = 20
					this.accept_list = [
						{range: 'audio/flac', suffixs: ['flac']},
						{range: 'audio/mp3', suffixs: ['mp3']},
						{range: 'audio/mpeg', suffixs: ['mp3']},
						{range: 'audio/wav', suffixs: ['wav']}
					]
					break;
				default:
					break;
			}
			if (!this.accept || this.accept.length === 0) {
				this.acceptSuffix = []
				this.accept_list.forEach(item => {
					this.acceptSuffix = [...this.acceptSuffix, ...item.suffixs]
				})
			}
		},
		getRootPath() {
			this.rootPath = localStorage.getItem('projectid') + '/' + this.tab_map[this.activeTab] + '/'
			this.activeDirCode = this.rootPath
		},
		tabChange(v) {
			this.format = ''
			this.activeTab = v
			this.images = []
			this.getRootPath()
			this.getAccept()
			this.getAllDir()
			this.getOssList()
			this.getFormatList()
		},
		calculateWidth() {
			const width = this.$refs.contentRef.clientWidth
			// 每行图片个数
			let rowSize = parseInt(width / this.imageWidth) - 1
			this.imagePadding = (width - rowSize * this.imageWidth) / (rowSize * 2)
		},
		selectChange(id, v) {
			if (!this.acceptSuffix.includes(this.calculateUrlSuffix(id))) {
				return false
			}
			if (v) {
				if (this.multiple) {
					this.selectItems.push(id)
				} else {
					this.selectItems = [id]
				}
			} else {
				let res = []
				this.selectItems.forEach(item => {
					if (item !== id) {
						res.push(item)
					}
				})
				this.selectItems = res
			}
			this.$emit('change', this.selectItems)
		},
		allSelectEvt(v) {
			if (v) {
				this.selectItems = []
				this.images.forEach(item => {
					if (this.acceptSuffix.includes(this.calculateUrlSuffix(item.objectUrl))) {
						this.selectItems.push(item.objectUrl)
					}
				})
			} else {
				this.selectItems = []
			}
		},
		deleteItem(ids) {
			if (ids) {
				this.confirm.showConfirm("确定删除素材？", null, '此操作可能会影响到应用端显示，' +
						'如素材正在被使用，请更新应用端的素材').then(() => {
					let array = ids.splice(',');
					for (let i = 0; i < array.length; i++) {
						const path = array[i]
						this.axios.post('/api/oss/delete_file?ossFileUrl=' + path).then((res) => {
							if (res.code === this.staticVal.Code.Success) {
								this.snackbar.success('文件删除成功');
								this.getOssList()
							}
						})
					}
				}).catch(() => {
				});
			}
		},
		saveGroup() {
			let validate = false
			let form = {}
			if (this.tabs === 0) {
				// 新建一级分组
				validate = this.$refs.form1.validate()
				form = this.form1
			} else if (this.tabs === 1) {
				// 新建二级分组
				validate = this.$refs.form2.validate()
				form = this.form2
			}
			if (validate) {
				this.axios.post('/api/oss/mkdir', form).then(res => {
					if (res.code === this.staticVal.Code.Success) {
						this.snackbar.success('新增成功');
						this.dialog = false
						this.getAllDir()
					}
				})
			}
		},
		getAllDir() {
			this.axios.post('/api/oss/getall_dir?type=' + this.tab_map[this.activeTab]).then(res => {
				this.dirList = res.data
			})
		},
		getDirFiles(item) {
			this.activeDirCode = item.code
		},
		uploadSuccess(end) {
			if (end) {
				this.snackbar.success('上传成功');
				this.getOssList()
				this.fileKey++
			}
		},
		getOssList() {
			if (!this.searchkey) {
				this.searchkey = ''
			}
			this.axios.post('/api/oss/list?path=' + this.activeDirCode + '&searchkey=' + this.searchkey + '&format=' + this.format).then(res => {
				this.images = res.data.fileList
				setTimeout(() => {
					this.calculateWidth()
				}, 500)
			})
		},
		openDialog() {
			this.dialog = true
			this.form1 = this.form2 = {
				dirName: '',
				group: ''
			}
			this.axios.post(this.select_data, {
				keyname: 'ossroot',
				params: [{key: 'ossroot', value: this.tab_map[this.activeTab]}]
			}).then(res => {
				this.selectList = res.data[0].values
			})
		},
		deleteDir(item) {
			this.confirm.showConfirm("确定删除目录" + item.desc + "及其文件？", null, '此操作可能会影响到应用端素材显示，如分组下的素材正在被使用，请更新应用端的素材').then(() => {
				this.axios.post('/api/oss/deletedir', {path: item.code}).then(res => {
					if (res.code === this.staticVal.Code.Success) {
						this.snackbar.success('删除成功');
						this.getAllDir()
						if (this.activeDirCode === item.code) {
							this.activeDirCode = this.rootPath
						}
					}
				})
			}).catch(() => {
			});
		},
		editDir(item) {
			this.renameItem = JSON.parse(JSON.stringify(item))
			this.renameDialog = true
		},
		renameDir() {
			this.axios.post('/api/oss/rename_dir', {newName: this.renameItem.desc, path: this.renameItem.code}).then(res => {
				if (res.code === this.staticVal.Code.Success) {
					this.getAllDir()
					this.snackbar.success('修改成功');
					this.renameDialog = false
				}
			})
		},
		getFileWidthHeight(v) {
			const _this = this
			if (_this.videoType.includes(this.calculateUrlSuffix(v))) {
				const audio = new Audio(v)
				// 元数据已加载
				audio.addEventListener("loadedmetadata", function (e) {
					_this.videoTimeLength = _this.durationTrans(audio.duration);
				});
			} else {
				let imgObj = new Image()
				imgObj.src = v
				imgObj.onload = () => {
					_this.imageWidthHeight = imgObj.width + ' x ' + imgObj.height
				}
			}
		},
		durationTrans(a) {
			let b = ""
			let h = parseInt(a / 3600),
					m = parseInt(a % 3600 / 60),
					s = parseInt(a % 3600 % 60);
			if (h > 0) {
				h = h < 10 ? '0' + h : h
				b += h + ":"
			}
			m = m < 10 ? '0' + m : m
			s = s < 10 ? '0' + s : s
			b += m + ":" + s
			return b;
		},
		updateFileName(item, e) {
			this.confirm.showConfirm("确定修改素材名称吗？", null, '此操作可能会影响到应用端显示，如素材正在被使用，请更新应用端的素材').then(() => {
				this.axios.post('/api/oss/rename_file', {newName: e.srcElement._value, oldUrl: item.objectUrl}).then(res => {
					this.getOssList()
					this.selectItems = []
					this.snackbar.success('文件重命名成功');
				})
			}).catch(() => {
			});
		}
	},
	filters: {
		fileSize(val) {
			if (!val) return "";
			if (val < 102.4) {
				return val + " B";
			}
			let num = val / 1024;
			if (num > 1024) {
				num = num / 1024;
				return num.toFixed(2) + " M";
			} else {
				return num.toFixed(2) + " K";
			}
		}
	},
	watch: {
		selectItems: {
			handler(v) {
				if (v && v.length) {
					this.indeterminate = v.length < this.images.length
					this.inputValue = v.length === this.images.length
				} else {
					this.indeterminate = false
					this.inputValue = false
				}
				this.deleteBtnStatus = !(v && v.length)
				this.showInfo = v && v.length === 1
				if (this.showInfo) {
					this.images.forEach(item => {
						if (v[0] === item.objectUrl) {
							this.item = item
							// 获取图片宽高
							this.getFileWidthHeight(v[0])
						}
					})
				}
			}
		},
		activeDirCode: {
			handler(v) {
				if (v) {
					this.getOssList()
				}
				this.selectItems = []
				this.inputValue = false
			}
		},
		accept: {
			handler(v) {
				this.acceptSuffix = []
				if (v && v.length > 0) {
					v.forEach(item => {
						this.acceptSuffix = [...this.acceptSuffix, ...item.suffixs]
					})
				}
			},
			immediate: true
		},
		remark: {
			handler(v) {
				if (v) {
					this.tabChange(v - 1)
				}
			},
			immediate: true
		}
	}
}
</script>

<style scoped lang="scss">
.d-container {
	background: #ffffff;
	border-radius: 8px;
	padding: 20px;
	display: flex;
	justify-content: space-between;

	.d-left-container {
		width: 100%;
		background: #F6F6FA;
		border-radius: 4px;
		color: #333333;
		font-size: 15px;

		.v-list {
			background: #F6F6FA;
		}
	}

	.dir-padding {
		padding: 17px 34px;

		&:hover {
			.operate-btn {
				display: inline-block;
				color: #ffffff;
			}
		}
	}

	.d-left-container-info {
		margin-top: 15px;
		height: 287px;

		.info-row {
			display: flex;
			margin-top: 15px;
		}

		.info-title {
			width: 82px;
		}
	}

	.d-right-container {
		height: 100%;
	}

	::v-deep {
		.v-input--selection-controls {
			margin-top: 0;
		}

		.v-input--selection-controls__input {
			background: #ffffff;
			border-radius: 4px;

			.v-icon {
				color: var(--v-primary-base) !important;
			}
		}

		.v-list-group--no-action > .v-list-group__items > .v-list-item {
			padding-left: 32px;
		}

		.v-text-field input {
			font-size: 14px;
		}

		.v-icon.v-icon--dense {
			font-size: 24px;
		}

	}

	.image-box:hover {
		.close-icon {
			display: inline-block !important;
		}
	}

	.active-dir {
		color: #ffffff;
		background: var(--v-primary-base);
		border-radius: 4px;
	}

	.operate-btn {
		display: none;
		cursor: pointer;
	}

	.d-upload {
		flex-basis: 160px;
		position: absolute;
		z-index: 999;
		width: 114px;
		height: 32px;
		flex: none;
		background: none;
		opacity: 0;

		::v-deep {
			.v-input__slot {
				min-height: 32px !important;
				cursor: pointer;
			}

			.v-input__append-outer {
				display: none;
			}
		}
	}

	.d-image-box {
		border-radius: 8px;
		height: 100%;
		width: 100%;
		background: #d8d8d8;
	}

}
</style>
