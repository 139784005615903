export default {
	// 表格头部设置
	headers: {
		type: Array,
		default: () => []
	},
	// 表格的分页数据
	listData: {
		type: Object,
		default: () => {
		}
	},
	// 表格加载动画
	loading: {
		type: Boolean,
		default: () => false
	},
	// 定义插槽
	itemScope: {
		type: Array,
		default: () => []
	},
	// 是否定义表格的按钮事件（如编辑、删除）选项
	btnAction: {
		type: Boolean,
		default: () => false
	},
	// 定义表格的按钮事件
	btnActionEvent: {
		type: Array,
		default: () => [
			{
				event: 'editItem',
				icon: 'bianji2-fill',
				color: 'var(--v-primary-base)',
				tooltip: '编辑'
			}
			, {
				event: 'deleteItem',
				icon: 'shanchu-fill',
				color: 'var(--v-redness-base)',
				tooltip: '删除'
			}
		]
	},
	// 展示表格顶部的左侧按钮
	showLeftBtn: {
		type: Boolean,
		default: () => false
	},
	showLeftSearchBtn: {
		type: Boolean,
		default: () => true
	},
	// 展示表格顶部的右侧按钮
	showRightBtn: {
		type: Boolean,
		default: () => false
	},
	leftConfig: {
		type: Array,
		default: () => []
	},
	rightConfig: {
		type: Array,
		default: () => []
	},
	customHtml: {
		type: Boolean,
		default: () => false
	},
	// 是否展示分页，默认展示
	showPagination: {
		type: Boolean,
		default: () => true
	},
	// 页面标题
	pageTitle: {
		type: String,
		default: 'default'
	},
	// 页面关键字段，如酒店code
	pageKey: {
		type: String,
		default: 'code'
	},
	// 正常的列表页面和模态框页面
	normalPage: {
		type: Boolean,
		default: true
	},
	listDeploy: {
		type: Object,
		default: () => ({row: []})
	},
	// 是否为模态框打开
	dialogTable: {
		type: Boolean,
		default: false
	},
	// 是否展示选择框
	showSelect: {
		type: Boolean,
		default: false
	},
	// 默认固定高度，用于外部控制
	fixedHeight: {
		type: Number,
		default: 0
	}
}
