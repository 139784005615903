<template>
  <v-dialog v-model="dialog" :width="width" persistent>
    <v-card class="pa-6" :color="bgColor">
      <div>
        <v-icon
          size="24"
          @click="close"
          style="position: absolute; right: 9px; margin-top: -15px"
          >iconfont icon-guanbi</v-icon
        >
      </div>
      <div class="title" style="margin-bottom: 8px">{{ title }}</div>
      <!-- d-flex justify-center -->
      <div class="d-flex justify-center" v-if="usageMode === 'tabs'">
        <div style="width: 72px">
          <v-tabs
            v-model="tab"
            vertical
            color="white white--text"
            class="rounded-lg"
            :height="contentHeight"
            hide-slider
            centered
          >
            <v-tab
            style="min-width:72px"
              v-for="(item, index) in items"
              :key="index"
              active-class="active-backdrop-color rounded"
            >
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </div>
        <div style="width: 100%;" :style="{height:contentHeight + 'px'}" class="overflow-auto">
          <v-tabs-items v-model="tab" style="margin-left: 16px">
            <v-tab-item v-for="(item, index) in items" :key="index">
              <v-card color="backdropGrey" flat>
                <!-- <component v-bind:is="currentTabComponent"></component> -->
                <!-- <dynameicForm :deploy="deploy"></dynameicForm> -->
                <!-- <commonTable></commonTable> -->
                <keep-alive>
                  <component :is="components[item.mainbody.module]" v-bind="item.mainbody.binds"></component>
                </keep-alive>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
      <!-- 自定义下使用插槽 -->
      <div v-else :style="{height:contentHeight + 'px'}" class="overflow-auto">
        <slot></slot>
      </div>
      <div>
        <slot name="footer"></slot>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import props from "./exhibition-data-box-props";
import dynameicForm from "@/components/dynamicForm/dynamicForm.vue";
import commonTable from "@/components/commonTable/commonTable.vue";

export default {
  mixins: [props],
  data() {
    return {
      currentTabComponent: 'forms',
      tab: 0,
      dialog2: true,
      components: {
        'forms':dynameicForm,
        'tables':commonTable,
      },
    };
  },
  methods: {
    close() {
      this.$emit("update:dialog", false)
    },
  },
  watch: {
    // dialog: {
    //   handler(val) {
    //     console.log(val)
    //     this.dialog2 = val;
    //   },
    //   // immediate: true
    // }
  }
};
</script>

<style>
</style>
