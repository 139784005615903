<template>
	<div style="display: inline-block; float: right">
		<template v-for="(config, index) in rightConfig">
			<v-btn
					depressed
					:key="index"
					:disabled="config.disabled/* || !operatePermissions.includes(config.rightCode)*/"
					:color="config.color ? config.color : 'primary'"
					@click="operate(config.event)"
					:class="index > 0 ? 'ml-4': ''">
				<v-icon size="16" class="iconfont mr-1">iconfont icon-{{ config.icon }}</v-icon>
				{{ config.label }}
			</v-btn>
		</template>
	</div>
</template>

<script>
export default {
	name: "right-btn",
	props: {
		rightConfig: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			operatePermissions: []
		}
	},
	methods: {
		operate(event) {
			this.$emit('operate', event);
		}
	},
	mounted() {
		let str = sessionStorage.getItem('operatePermissionKey');
		if (str) {
			this.operatePermissions = JSON.parse(str);
		}
	}
}
</script>

<style scoped lang="scss">

</style>
