<template>
	<!--
				使用v-dialog的retain-focus接口，是避免跟富文本编辑器的表单冲突，同时也可以避免其他插件的弹出框里面表单冲突
		-->
	<v-dialog
			v-model="dialog"
			:content-class="center ? '' : 'ybj'"
			:max-width="width"
			:transition="transition"
			persistent
			:retain-focus="false"
	>
		<v-card :height="height">
			<div class="px-6 py-4 d-flex justify-space-between">
        <span style="
            font-size: 24px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333333;"
        >{{ title }}</span>
				<v-icon
						size="24"
						@click="close"
						style="position: absolute; right: 24px; margin-top: 10px"
				>iconfont icon-guanbi
				</v-icon
				>
			</div>
			<v-divider v-if="!ridDivision"></v-divider>
			<v-card-text
					class="px-3 pt-2"
					style="height: calc(100% - 138px); overflow: auto"
			>
				<div>
					<Form :deploy="listDeploy" ref="Form"></Form>
				</div>
			</v-card-text>
			<v-divider v-if="!ridDivision"></v-divider>
			<div class="px-6 py-4" :style="{ textAlign: center_btn ? 'center' : footer_btn_local }">
				<v-btn depressed outlined color="primary" width="72" @click="reset" :disabled="btn_disabled_status"
				>{{ leftbtn_text }}
				</v-btn
				>
				<v-btn
						class="ml-6"
						:disabled="btn_disabled_status"
						:loading="load"
						depressed
						color="primary"
						width="72"
						@click="save"
				>
					{{ rightbtn_text }}
				</v-btn>
				<div>
					<slot></slot>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import props from "./form-menu-props";
import Form from "@/components/dynamicForm/dynamicForm.vue";

export default {
	mixins: [props],
	components: {
		Form,
	},
	provide() {
		return {
			form_menu: this,
		};
	},
	data() {
		return {
			load: false,
			interior: false,
			form: {},
			validate: {}
		};
	},
	methods: {
		submit(v) {
			console.log(v);
		},
		// 关闭窗口
		close() {
			this.$emit("update:dialog", false);
		},
		// 重置表单函数
		reset() {
			//   console.log(this.$refs.Form.reset());
			this.load = false;
			if (this.cancel_reset) {
				this.$emit("resetForm", this.$refs.Form.form);
			} else {
				this.$emit("resetForm", this.$refs.Form.reset());
			}
		},
		// 验证保存表单
		save() {
			this.validate = this.$refs.Form.validate();
			this.interior = this.validate.done;
			setTimeout(() => {
				// console.log(this.form, this.validate);
				this.form = Object.assign(this.validate, {done: this.interior});
				console.log(this.form, this.interior);
				if (this.interior) {
					this.load = true;
				}
				this.$emit("validateSave", this.form, (v) => {
					this.finishLoad(v);
					this.close();
				});
			}, 100);
		},
		finishLoad(v) {
			this.load = false;
			if (v) {
				// v=true，是仅进行重置
				this.$refs.Form.reset();
			} else {
				this.reset();
			}
		},
	},
	watch: {
		dialog: {
			handler(bool) {
				// console.log(this.listDeploy)
			}
		}
	},
};
</script>

<style>
.ybj {
	position: absolute;
	top: -24px;
	right: -25px;
	max-height: 100% !important;
}
</style>
