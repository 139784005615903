<template>
  <v-select
    :label="label"
    :items="items"
    :clearable="clearable"
    :multiple="multiple"
    :disabled="disabled"
    :hide-details="hideDetails"
    :color="color"
    :item-color="itemColor"
    :dense="dense"
    :rules="rules"
    :outlined="outlined"
    :autofocus="autofocus"
    :placeholder="placeholder"
    :no-data-text="noDataText"
    :item-text="itemText"
    :item-value="itemValue"
    @change="modification"
    v-bind="additional"
    :required="required"
    :value="values"
    :chips="small"
    :small-chips="small"
    :hint="hint"
    :persistent-hint="persistentHint"
    :readonly="readonly"
  >
  </v-select>
</template>

<script>
import props from "./w-select-props";
export default {
  mixins: [props],
  data() {
    return {
      current: "",
    };
  },
  computed: {
    values() {
      if (this.current instanceof Array) {
        return this.current;
      }
      return this.multiple ? this.current.split(",") : this.current;
    },
    small() {
      let values = "";
      if (this.values instanceof Array) {
        // 因为类型是数组，同时避免数组中有错误的空字符串，执行归约操作
        // reduction=默认值是第二个参数""
        values = this.values.reduce((reduction, element) => {
          return reduction + element;
        }, "");
      } else {
        values = this.values;
      }
      return this.chips && values ? true : false;
    },
  },
  methods: {
    modification(v) {
      this.current = v;
      if (this.multiple) {
        // 判断返回数组类型是否格式化
        this.$emit("change", this.arrayType ? v : v.join());
      } else {
        this.$emit("change", v);
      }
    },
    // 查询数组中符合指定属性属性值的对象返回
    inquire(val) {
      let obj = this.items.find((obj) => obj[this.someToValue] === val);
      return obj && obj[this.itemValue];
    },
    // 处理指定属性，同时又是开启多选下拉框模式
    someToValExtraHandle(current) {
      let currents = JSON.parse(JSON.stringify(current));
      let len = currents.length;
      // 判断是不是空数组
      if (len > 0) {
        let arrs = [];
        for (const obj of this.items) {
          for (let i = 0; i < len; i++) {
            if (obj[this.someToValue] === currents[i]) {
              arrs.push(obj);
              currents.splice(i, 1);
              len--;
            }
          }
        }
        return arrs;
      }
      return [];
    },
  },
  watch: {
    value: {
      handler(val) {
        // 判断有没有指定某个属性的属性值作为原来使用code筛选
        if (this.someToValue) {
          // 判断是不是需要多选处理
          if (this.multiple) {
            let currents = val instanceof Array ? val : val.split(",");
            // 调用指定属性处理接口配套的额外的数组处理

            this.current = this.someToValExtraHandle(
              currents[0] ? currents : []
            );
            Promise.resolve().then(() => {
              this.modification(
                this.current.map((obj) => {
                  return obj[this.itemValue];
                }),
                false
              );
            });
          } else {
            // 单选处理
            // 获得查询结果
            this.current = this.inquire(val);

            // 使用异步调用modification()触发change赋值，覆盖掉初始化的this.form[property]
            // = value;
            Promise.resolve().then(() => {
              this.modification(this.current, false);
            });
          }
        } else {
          this.current = val;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
</style>