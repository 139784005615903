<template>
  <div class="d-flex align-center">
    <span 
    style="margin-right: 9px;position:relative;" 
    v-if="labelLeft"
    :style="{
        color: have?'var(--v-primary-base)':'',
        top: hideDetails?'10px':'0px'
    }"
    >{{ labelLeft }}</span>
    <v-switch
      v-bind="extension"
      :label="labelRight"
      style="display: inline-block"
      :color="color"
      v-model="have"
      @change="modification"
      :inset="inset"
      :hide-details="hideDetails"
      :dense="dense"
      :disabled="cdisabled"
    ></v-switch>
    <!-- {{have}}{{value}} -->
  </div>
</template>

<script>
import props from "./w-switches-props.js";
export default {
  mixins: [props],
  data() {
    return {
        have: false,
        hidedetails: true
    };
  },
  computed: {
    labelLeft() {
      return this.direction === "left" ? this.label : "";
    },
    labelRight() {
      return this.direction === "right" ? this.label : undefined;
    },
    labelColor() {
        return this.have?'var(--v-primary-base)':'';
    },
    cdisabled() {
      if (this.rightCode && sessionStorage.getItem("")) {
        
      }
    }
  },
  methods: {
      modification(val) {
          this.have = val;
          this.$emit("change",val);
          this.$emit("input", val);
      }
  },
  watch: {
      value: {
          handler(current) {
              this.have = current;
          },
          immediate: true
      },
  },
  mounted() {
    
  }
};
</script>

<style>
</style>