// import {url} from './configuration.js';
// console.log(url)
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './utils/global-components'
// store结构
import store from './store';
// 引入iconfont
import '../src/assets/icon/iconfont.css';
import '../src/styles/base.scss';
import '@/styles/main.scss';
// axios
import axios from '@/utils/requets.js';
import VueAxios from 'vue-axios';

// Vue.use(VueAxios, axios);
// 消息条
import {snackbar} from 'vuetify2-expand';
import Snackbar from '@/utils/snackbar.js';
// 定义的常量
import staticVal from '@/utils/global-variable.js';
// 公共的js方法
import {met} from '@/utils/global-methods.js';
// 确认弹框组件
import Confirm from "@/components/confirmBox/confirmBox.js";
// 格式化时间插件
import moment from 'moment';
// uid插件
import UUID from 'vue-uuid';

// 图片预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
	Options: {
		inline: true,
		button: true,
		navbar: true,
		title: true,
		toolbar: true,
		tooltip: true,
		movable: true,
		zoomable: true,
		rotatable: true,
		transition: true,
		fullscreen: true,
		keyboard: true,
		url: 'data-source'
	}
})

Vue.use(snackbar, {
	vuetify, params: {
		top: true,
		app: true,
		dark: true
	}
});
Vue.prototype.snackbar = Snackbar;

Vue.prototype.globalMethods = met;

Vue.prototype.staticVal = staticVal;

Vue.prototype.confirm = Confirm;

Vue.prototype.moment = moment;

Vue.use(UUID);

// 安装验证插件
// import VeeValidate, {Validator} from 'vee-validate';
// const config = {
//   aria: true,
//   classNames: {},
//   classes: false,
//   delay: 0,
//   dictionary: null,
//   errorBagName: 'errors', // change if property conflicts
//   events: 'input|blur',
//   fieldsBagName: 'fields',
//   i18n: null, // the vue-i18n plugin instance
//   i18nRootKey: 'validations', // the nested key under which the validation messages will be located
//   inject: true,
//   locale: 'en',
//   validity: false,
//   useConstraintAttrs: true
// };
// Vue.use(VeeValidate,config)

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
	store.commit('setAppBarTitle', to.meta);
	if (store.state.token) {
		if (to.path === '/' || to.path === '/login') {
			// 已经登录直接跳转到首页
			next({path: '/index'});
		} else {
			if (to.name === 'Form') {
				return next();
			}
			if (['/403', '/404', '/blank'].includes(to.path)) {
				localStorage.removeItem('activeMenuKey');
				next();
			} else {
				let permissionPaths = sessionStorage.getItem('permissionPathsKey');
				let permissions = sessionStorage.getItem('permissionKey');
				if (permissionPaths && permissions) {
					let paths = JSON.parse(permissionPaths);
					let permissionArray = JSON.parse(permissions);
					if (permissionArray.includes(paths[to.path])) {
						// 激活选中的菜单项
						localStorage.setItem("activeMenuKey", JSON.stringify({
							"id": paths[to.path]
						}));
						store.state.activeMenu = {id: paths[to.path]}
						next();
					} else {
						// 403/404
						next(to.name ? {path: '/403'} : {path: '/404'});
					}
				} else {
					// 默认空白页
					next({path: '/blank'});
				}
			}
		}
	} else {
		if (to.path === "/login") {
			next();
		} else {
			next({path: '/login'});
		}
	}
});
// Vue.prototype.ass = 123; // 测试文件创建流，添加个属性，他没有作用

axios().then(res => {
	Vue.use(VueAxios, res);
	// Vue.prototype?.loadconf();
	// axios?.loadconf()
})
const vm = new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
