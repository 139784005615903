import Vue from 'vue'

// 下拉框get地址
Vue.prototype.select_data = "/api/custom/select_data";

import commonTable from "@/components/commonTable/commonTable.vue";

Vue.component('commonTable', commonTable);
Vue.component('w-switches', () => import('@/components/w-switches/w-switches.vue'));

// 函数式组件声明区
Vue.component('w-cols-title', {
    functional: true,
    props: {
        title: {
            type: String,
            default: ""
        },
        top: {
            type: Number,
            default: 0
        },
        bottom: {
            type: Number,
            default: 0
        }
    },
    render: function (c, ctx) {
        function init() {
            ctx.data.style = `color:var(--v-drawerFont-base);font-weight: bold;
            margin-top:${ctx.props.top}px;margin-bottom:${ctx.props.bottom}px;`
            ctx.children = [
                ctx.props.title
            ]
            return "div"
        }
        return c(init(), ctx.data,
            ctx.children)
    }
})
