<template>
	<v-dialog :width="width" v-model="dialog" persistent>
		<div style="background: #ffffff">
			<div style="float: right; margin: 5px 10px 0 0">
				<v-icon size="24" @click="closeCallback">iconfont icon-guanbi</v-icon>
			</div>
			<div style="clear: both;"></div>
			<template v-if="prompt">
				<div style="color: #333333; font-weight: bold; text-align: center">{{ content }}</div>
				<div class="pl-8 pr-8" style="color: #F25757; font-size: 14px; margin-top: 8px; text-align: center">{{ prompt }}</div>
			</template>
			<template v-else>
				<div style="color: #F25757; font-weight: bold; text-align: center">{{ content }}</div>
			</template>
			<div style="text-align: center; padding: 32px">
				<v-btn small depressed @click="closeCallback" color="primary" outlined>取消</v-btn>
				<v-btn small depressed @click="confirmCallback" color="primary" class="ml-8">确定</v-btn>
			</div>
		</div>
	</v-dialog>
</template>

<script>

export default {
	data() {
		return {
			width: '',
			dialog: false,
			resolve: '',
			reject: '',
			content: '', // 显示的内容,
			prompt: '', // 提示的信息，不定义改值则默认为删除的提示
		}
	},
	methods: {
		confirmCallback() {
			this.dialog = false;
			this.resolve(); // then 获取
		},
		closeCallback() {
			this.dialog = false;
			this.reject(); // catch 获取
		},
	},
	watch: {
		dialog(v) {
			document.body.removeChild(this.$el);
		}
	}
}
</script>

<style>

</style>
