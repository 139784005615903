export default {
    props: {
        // 表单激活api，赋值格式要求:dialog.sync=""
        dialog: {
            type: Boolean,
            default: false
        },
        // 表单标题
        title: {
            type: String,
            default: "新建"
        },
        // 表单列表配置对象
        listDeploy: {
            type: Object,
            default: () => ({
                row: [
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: 'vf',
                        connector: {
                            props: {
                                label: "信息",
                                required: true
                            }
                        },
                        // default: ,
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        default: "",
                        connector: {
                            class: 'asterisk',
                            props: {
                                label: "信息"
                            }
                        },
                    },
                    {
                        cols: 6,
                        subassembly: 'WAutocomplete',
                        property: 'fb',
                        connector: {
                            props: {
                                items: [{ code: true, desc: '富' }, { code: false, desc: '酒吧' }]
                            }
                        },
                        default: true
                    },
                    {
                        cols: 6,
                        subassembly: "WSelect",
                        default: 123,
                        connector: {
                            props: {
                                label: "信息"
                            }
                        },
                    },
                    {
                        cols: 12,
                        subassembly: 'WFileinput',
                        connector: {
                            // class: 'asterisk',
                            props: {
                                min: 1,
                                required: true,
                                label: '上传图片',
                                uniline: true
                            }
                        }
                    },
                    {
                        cols: 6,
                        subassembly: "div",
                        default: "888",
                    },
                    {
                        cols: 6,
                        subassembly: "div",
                        default: "888",
                    }
                ]
            })
        },
        // 设置弹出框的宽度，默认为50%，如果是像素需要把px一起赋值
        width: {
            type: String,
            default: "50%"
        },
        // 设置弹出框的高度，默认为100vh，如果是像素单位需要把px一起赋值
        height: {
            type: String,
            default: "100vh"
        },
        // 设置对话框的渲染动画，这里默认是靠边类型, 选择普通居中类型值是：dialog-transition
        // 也可以根据自己想传的定义
        transition: {
            type: String,
            default: "slide-x-transition"
        },
        // 设置对话框为垂直水平居中显示
        center: {
            type: Boolean,
            default: false
        },
        // 设置对话框中的重置、保存按钮是否居中
        center_btn: {
            type: Boolean,
            default: false
        },
        footer_btn_local: {
            type: String,
            default: 'left'
        },
        // 左按钮
        leftbtn_text: {
            type: String,
            default: '重置'
        },
        // 右按钮
        rightbtn_text: {
            type: String,
            default: '保存'
        },
        // 取消重置按钮默认调用重置方法功能
        cancel_reset: {
            type: Boolean,
            default: false
        },
        // 去除分割线
        ridDivision: {
            type: Boolean,
            default: false
        },
        btn_disabled_status: {
            type: Boolean,
            default: false
        }
    }
}
