<script>
let age = 0;
import {VContainer, VRow, VCol, VForm, VTextField} from "vuetify/lib";
import Props from "./dynamicForm-props";
import methods from "./dynameicForm-methods";
import {hasCapita} from "@/utils/global-methods";
import WAutocomplete from "@/components/w-autocomplete/w-autocomplete.vue";
import WSelect from "@/components/w-select/w-select.vue";
import WFileinput from "@/components/w-file-input/w-file-input";
import WSwitches from "@/components/w-switches/w-switches.vue";
import MaterialFile from "@/components/common-material/material.vue";

export default {
	mixins: [Props, methods],
	render(h) {
		const nodes = this.creationVnodes(this.deploy.row);
		return h(
				VForm,
				{
					props: {
						value: true,
					},
					ref: "form",
					key: "dynamicForm:" + this.myKey,
				},
				[this.$createElement(VContainer, [h(VRow, nodes)])]
		);
	},
	inject: {
		form_menu: {
			from: "form_menu",
			default: {},
		},
	},
	provide() {
		return {
			form_menu: this.form_menu,
		};
	},
	data() {
		return {
			// 存储处理好的组件节点，交由render渲染
			nodes: [],

			// 统一预设值对象
			// 考虑到可能有重复对组件节点进行的设置，抽象出来写在一个预设值对象中
			// 属性名，为组件名，以此对应判断处理
			unifyPreinstall: {
				// 例如："属性名": "属性值"
			},

			// 部分预设值对象
			// 只应用在指定的部分对象组件中
			portionPreinstall: {
				// 例如："组件名": { 属性: "" }
			},

			// 保存表单组件产生的value值对象
			// 他根据配置对象上的property创建属性名, 然后对应属性名赋值value
			form: {},

			// 已导入组件的匹配列表
			matchingList: new Map([
				// ["VBtn", VBtn],
				["VTextField", VTextField],
				["WAutocomplete", WAutocomplete],
				["WSelect", WSelect],
				["WFileinput", WFileinput],
				["WSwitches", WSwitches],
				['MaterialFile', MaterialFile]
			]),
			myKey: 0,
			myKey2: 0,
			ugo: "999",
		};
	},
	methods: {
		creationVnodes(vnodes) {
			let result = [];
			if (!Array.isArray(vnodes)) {
				console.warn(
						"警告:调用者组件使用了动态表单组件，但是没有提供可渲染的列表数组"
				);
				return "";
			}

			let len = vnodes.length;
			for (let i = 0; i < len; i++) {
				result.push(this.genFormItem(vnodes[i]));
			}
			return result;
		},
		genFormItem(item) {
			let VNode;
			if (item.subassembly?.constructor?.name === "VNode") {
				VNode = item.subassembly;
			} else {
				VNode = this.creationVnode(item);
			}
			let vndes = [VNode ?? ""];
			if (item.insertBefore) {
				vndes.unshift(item.insertBefore);
			}
			if (item.insertLater) {
				vndes.push(item.insertLater);
			}
			return this.$createElement(
					VCol,
					{
						...item.setCol,
						props: {
							cols: item.cols,
						},
						class: "pb-0 pt-0",
					},
					vndes
			);
		},
		creationVCol(cols, VNode) {
			return this.$createElement(
					VCol,
					{
						props: {
							cols: cols,
						},
					},
					[VNode]
			);
		},
		creationVnode(item) {
			let vnode;
			if (typeof item.subassembly === "string") {
				vnode = this.checkComponentName(item.subassembly);
			} else if (item.subassembly instanceof Object) {
				vnode = item.subassembly;
			} else {
				throw new Error(`subassembly赋值错误: 类型错误，不是一个表单组件已内置组件对象字符串名，也不是vuetify的ui
          组件，如果要使用自定义组件，请把import导入的组件变量赋值上去。
          `);
			}
			let option = {};
			let verifyCapital;
			if (typeof item.subassembly === "string") {
				verifyCapital = hasCapita(item.subassembly[0] + item.subassembly[1]);
			} else if (item.subassembly instanceof Object && item.subassembly.name) {
				verifyCapital = hasCapita(
						item.subassembly.name[0] + item.subassembly.name[1]
				);
			} else {
				verifyCapital = true;
			}
			if (item.connector) {
				Object.assign(option, item.connector);
			} else {
				item.connector = {};
			}
			if (!option.props) {
				option.props = {};
			}
			if (!option.on) {
				option.on = {};
			} else {
				let especially = option.on.especially;
				if (especially) {
					option.on = {
						especially,
					};
				}
			}
			let change = ($event) => {
				if (item.property) {
					if (item.property instanceof Array) {
						let value = item.deconstruction($event);
						for (let i = 0; i < item.property.length; i++) {
							this.form[item.property[i]] = value[i] || '';
						}
					} else {
						this.form[item.property] = $event;
					}
					age = $event;
				} else if (item.merge) {
					console.log($event);
					Object.assign(this.form, $event);
				}
				this.$emit("change", [item.property, $event]);
				item.connector?.on?.especially && item.connector.on.especially($event);
			};
			if (!option.on?.change) {
				option.on.change = change;
			} else {
			}
			if (item.property) {
				if (item.property instanceof Array) {
					for (const property of item.property) {
						if (!this.form[property]) {
							this.form[property] = "";
						}
					}
				} else {
					if (!this.form[item.property]) {
						this.form[item.property] = "";
					} else if (item.connector.props && "value" in item.connector.props) {
						this.form[item.property] = item.connector.props.value;
					}
				}
			}
			if (this.typeCheck(item, "default") || item.default == 0) {
				if (
						(item.subassembly && verifyCapital) ||
						item.subassembly instanceof Object
				) {
					if (!option.props.value) {
						option.props.value = item.default;
						if (item.property) {
							this.form[item.property] = item.default;
						}
					} else {
						option.props.value = item.default;
						this.form[item.property] = option.props.value;
					}
				} else {
					option.domProps = {
						innerText: item.default,
					};
				}
			} else if (
					this.typeCheck(option.props, "value") ||
					option.props.value == 0
			) {
				if (item.property) {
					this.form[item.property] = option.props.value;
				}
			}
			if (item.connector?.props?.required) {
				if (option?.class && option?.class instanceof Object) {
				} else {
					option.class = option.props.title ? "" : "asterisk";
				}
				if (!item.connector.props?.rules || item.connector.props?.rules?.length === 0) {
					option.props.rules = [
						(v) => {
							let val = "";
							if (v !== undefined && typeof v !== "string") {
								val = JSON.stringify(v);
							} else {
								val = v;
							}
							if (val?.length > 0 && !val.match(/^[ ]*$/) && val !== "[]") {
								return true;
							}
							return (
									(item.connector.props?.label === undefined
											? ""
											: item.connector.props.label) + "不能为空"
							);
						},
					];
				}
			} else {
				option.props.rules = []
			}
			const element = this.$createElement(
					vnode,
					option,
					typeof item.slots === "function" && item.slots(this.$createElement)
			);
			return element;
		},
		checkComponentName(name) {
			if (this.matchingList.get(name)) {
				return this.matchingList.get(name);
			} else {
				if (name && hasCapita(name[0] + name[1])) {
					let VElement = "";
					({[name]: VElement} = require("vuetify/lib"));
					if (VElement instanceof Function) {
						this.matchingList.set([name, VElement]);
					}
					return VElement;
				}
				if (typeof name === "string") {
					return name;
				}
			}
		},
	},
};
</script>
